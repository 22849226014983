import { Chart } from "../../../../datamodel/chart";
import { ChartPoint } from "../../../../datamodel/chart-point";
import { ChartTypes } from "../../../../datamodel/chart-types";
import { ChartPointV1 } from "./chart-point.v1";

/**
 *
 */
export class ChartV1 {
    public title?: string;
    public type?: ChartTypes;
    public data: Array<ChartPoint> = [];
    public centerX?: number;
    public unitX?: string;
    public unitY?: string;

    public showXAxis: boolean = true;
    public showYAxis: boolean = true;

    public showMin: boolean = true;
    public showMax: boolean = true;
    public showAverage: boolean = true;

    public static fromEntity(chart: Chart): ChartV1 {
        const v1: ChartV1 = new ChartV1();
        v1.title = chart.title;
        v1.centerX = chart.centerX;
        v1.unitX = chart.unitX;
        v1.unitY = chart.unitY;
        v1.showXAxis = chart.showXAxis;
        v1.showYAxis = chart.showYAxis;
        v1.showMin = chart.showMin;
        v1.showMax = chart.showMax;
        v1.showAverage = chart.showAverage;

        v1.data = [];
        for (const chartPoint of chart.data) {
            v1.data.push(ChartPointV1.fromEntity(chartPoint));
        }

        return v1;
    }

    public static toEntity(v1: ChartV1): Chart {
        const entity: Chart = new Chart();
        entity.title = v1.title;
        entity.centerX = v1.centerX;
        entity.unitX = v1.unitX;
        entity.unitY = v1.unitY;
        entity.showXAxis = v1.showXAxis;
        entity.showYAxis = v1.showYAxis;
        entity.showMin = v1.showMin;
        entity.showMax = v1.showMax;
        entity.showAverage = v1.showAverage;

        entity.data = [];
        for (const chartPointV1 of v1.data) {
            entity.data.push(ChartPointV1.toEntity(chartPointV1));
        }

        return entity;
    }
}
