import { ContentTable, TableCell } from "pdfmake/interfaces";

import { Column } from "./column";
import { Row } from "./row";

/**
 * This class allows to render a table on a PDF.
 */
export class Table {

    public rows: Array<Row> = [];
    public columns: Array<Column> = [];
    public renderHeaderRow: boolean = true;
    public renderBorders: boolean = true;

    public render(templateSuffix: string): ContentTable {
        return {
            table: {
                body: this.renderTableCells(templateSuffix),
                widths: this.renderWidths(),
                headerRows: this.renderHeaderRow ? 1 : 0
            },
            style: `dataTable${templateSuffix}`,
            layout: this.renderBorders ? undefined : "noBorders",
            noWrap: false
        };
    }

    private renderTableCells(templateSuffix: string): Array<Array<TableCell>> {
        const cells: Array<Array<TableCell>> = [];

        if (this.renderHeaderRow) {
            const headerRow: Array<TableCell> = [];
            for (const column of this.columns) {
                headerRow.push({
                    text: column.title,
                    style: `dataTableHeader${templateSuffix}`,
                    fillColor: column.fillColor,
                    color: column.color
                });
            }
            cells.push(headerRow);
        }

        for (const dataRow of this.rows) {
            const dataCells: Array<TableCell> = [];
            for (const c of dataRow.cells) {
                if (c) {
                    dataCells.push(c);
                } else {
                    dataCells.push({});
                }

            }
            cells.push(dataCells);
        }
        return cells;
    }

    private renderWidths(): Array<string> {
        const widths: Array<string> = [];
        this.columns.forEach((column: Column) => {
            widths.push(column.width);
        });
        return widths;
    }
}
