import { ChartPoint } from "../../../../datamodel/chart-point";

/**
 *
 */
export class ChartPointV1 {
    public x: number = 0;
    public y: number = 0;

    public static fromEntity(chartPoint: ChartPoint): ChartPointV1 {
        return {
            x: chartPoint.x,
            y: chartPoint.y
        };
    }

    public static toEntity(v1: ChartPointV1): ChartPoint {
        return {
            x: v1.x,
            y: v1.y
        };
    }
}
