import { SchemaMetaData } from "../schema-meta-data";
import { JobTemplateV1 } from "./job-template.v1";

/**
 * This class defines the json structure for an exported job template in version 1.
 */
export class JobTemplateFileV1 {
    public metaData: SchemaMetaData|undefined;
    public jobTemplate: JobTemplateV1|undefined;
}
