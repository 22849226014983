<app-menubar [showBackButton]="false" [hideTitleOniOS]="true" header="QuickMode.title">
    <div class="optionsMenu">
        <button [matMenuTriggerFor]="optionsMenu" class="iconButton" color="primary" mat-stroked-button>
            <fa-icon [icon]="(exportingPdf || exportingCsv ? appIcons.loading : appIcons.extraMenu)" [animation]="(exportingPdf ? 'spin' : undefined)" class="align-right"></fa-icon>
        </button>
        <mat-menu #optionsMenu>
            <mat-action-list>
                <button (click)="exportCsv()" class="iconButton menuItem" mat-list-item [disabled]="measurements.length <= 0">
                    <fa-icon [icon]="appIcons.csvIcon" matListItemIcon></fa-icon>
                    <span matListItemTitle>{{'CsvExport.export' | translate}}</span>
                </button>

                <button (click)="exportPdf()" class="iconButton menuItem" mat-list-item [disabled]="measurements.length <= 0">
                    <fa-icon [icon]="appIcons.pdfIcon" matListItemIcon></fa-icon>
                    <span matListItemTitle>{{'PdfExport.export' | translate}}</span>
                </button>

                <mat-divider></mat-divider>

                <button (click)="deleteAllMeasurements()" class="iconButton menuItem" mat-list-item [disabled]="measurements.length <= 0">
                    <fa-icon [icon]="appIcons.trash" matListItemIcon></fa-icon>
                    <span matListItemTitle>{{'QuickMode.deleteAllMeasurements' | translate}}</span>
                </button>
            </mat-action-list>
        </mat-menu>
    </div>
</app-menubar>

<ion-content [fullscreen]="true" class="content ion-padding">
    <ion-header collapse="condense">
        <ion-toolbar>
            <ion-title size="large">{{'QuickMode.title' | translate}}</ion-title>
        </ion-toolbar>
    </ion-header>

    <app-measurement-table #measurementTableComponent
                           (deleteMeasurement)="deleteMeasurement($event)"
                           [highlightLastMeasurement]="true"
                           [measurements]="measurements"
                           [showStatistics]="true">
    </app-measurement-table>
</ion-content>
