import { AbstractEntityV1 } from "./abstract-entity.v1";
import { PartV1 } from "./part.v1";
import { PropertyV1 } from "./property.v1";

/**
 * Export container for job templates.
 */
export class JobTemplateV1 extends AbstractEntityV1 {
    public parts: Array<PartV1> = [];
    public date: Date|undefined;
    public properties: Array<PropertyV1> = [];
}
