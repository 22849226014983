<input #imageSelect accept="image/png, image/jpeg" class="notRendered" type="file" />
<mat-card>
    <div *ngIf="caption" class="caption">{{caption}}</div>
    <div class="image">
        <div class="imageContainer" #imageContainer>
            <base-marker-annotation
                #markerAnnotationComponent
                [(selectedMarker)]="selectedMarker"
                [hidden]="!imageSource"
                [image]="imageSource"
                [markers]="markers"
                (selectedMarkerChange)="selectedMarkerChanged()"
                (changeDetected)="markersChanged()"
                [mode]="mode">
            </base-marker-annotation>
        </div>

        <ng-container *ngIf="mode == 'edit'">
            <div *ngIf="!imageSource" class="overlay">
                <button (click)="selectImage()" color="primary" mat-stroked-button>{{'JobDesigner.addImage' | translate}}</button>
            </div>
        </ng-container>
    </div>

    <ng-container *ngIf="mode == 'edit' && imageSource && this.markerAnnotationComponent">
        <div class="menuOverlay">
            <div class="menuItems">
                <button (click)="selectImage()" color="primary" mat-icon-button>
                    <fa-icon [icon]="appIcons.changeImage"></fa-icon>
                </button>

                <button (click)="addMarker()" *ngIf="!this.markerAnnotationComponent?.currentlyMovingElementIsNewMarker"
                        color="primary" mat-icon-button>
                    <fa-icon [icon]="appIcons.markerIcon"></fa-icon>
                </button>
                <button (click)="cancelAddMarker()"
                        *ngIf="this.markerAnnotationComponent.currentlyMovingElementIsNewMarker" color="accent"
                        mat-icon-button>
                    <fa-icon [icon]="appIcons.markerIcon"></fa-icon>
                </button>
                <button (click)="deleteAllMarkers()"
                        color="accent"
                        mat-icon-button>
                    <fa-icon [icon]="appIcons.trash"></fa-icon>
                </button>

            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="mode == 'markerSelection'">
        <div class="markerSelection">
            <button (click)="selectPreviousMarker()" [disabled]="!canSelectPreviousMarker || disabled" mat-fab>
                <fa-icon [icon]="appIcons.previousMarkerIcon"></fa-icon>
            </button>
            <span>{{selectedMarker?.order}}</span>
            <button (click)="selectNextMarker()" [disabled]="!canSelectNextMarker || disabled" mat-fab>
                <fa-icon [icon]="appIcons.nextMarkerIcon"></fa-icon>
            </button>
        </div>
    </ng-container>

    <app-property-list
        (propertiesUpdatedEvent)="setPartProperties($event)"
        *ngIf="mode != 'markerSelection'"
        [isReadonly]="isReadonly"
        [propertyItems]="getPartProperties()">
    </app-property-list>
</mat-card>
