import { UiHelper } from "../ui-helper";
import { StatisticRecord } from "./statistic-record";

/**
 * StatisticsCalculator allows to track values and provides methods to get statistics of them.
 */
export class StatisticsCalculator {

    private values: Array<StatisticRecord> = [];

    public add(name: string, value: string, unit: string|undefined): void {
        const valueAsNumber: number = Number(value);

        this.values.push({
            name: name,
            measurementPoint: undefined,
            value: isNaN(valueAsNumber) ? undefined : valueAsNumber,
            unit: unit
        });

    }

    public addForMeasurementPoint(name: string, measurementPoint: string, value: string, unit: string|undefined): void {
        const valueAsNumber: number = Number(value);
        this.values.push({
            name: name,
            measurementPoint: measurementPoint,
            value: isNaN(valueAsNumber) ? undefined : valueAsNumber,
            unit: unit
        });

    }

    public getNames(): Array<string> {
        const names: Array<string> = [];
        for (const record of this.values) {
            if (record.name && !names.includes(record.name)) {
                names.push(record.name);
            }
        }
        return names;
    }

    public getUnitForName(name: string): string|undefined {
        const matches: Array<StatisticRecord> = this.values.filter((v: StatisticRecord) => v.name == name);
        return matches.length <= 0 ? undefined : matches[0].unit;
    }

    public getMeasurementPoints(): Array<string> {
        const mps: Array<string> = [];
        for (const record of this.values) {
            if (record.measurementPoint && !mps.includes(record.measurementPoint)) {
                mps.push(record.measurementPoint);
            }
        }
        return mps;
    }

    public min(name: string, measurementPoint?: string): string {
        let precision: number = 1;
        let min: number|undefined;
        for (const v of this.values) {
            if (v.name == name && v.value) {
                if (measurementPoint && v.measurementPoint != measurementPoint) {
                    continue;
                }

                precision = Math.max(UiHelper.getPrecisionForUnit(v.unit), precision);
                if (!min || v.value < min) {
                    min = v.value;
                }
            }
        }
        return min?.toFixed(precision) ?? "";
    }

    public max(name: string, measurementPoint?: string): string {
        let precision: number = 1;
        let max: number|undefined;
        for (const v of this.values) {
            if (v.name == name && v.value) {
                if (measurementPoint && v.measurementPoint != measurementPoint) {
                    continue;
                }

                precision = Math.max(UiHelper.getPrecisionForUnit(v.unit), precision);
                if (!max || v.value > max) {
                    max = v.value;
                }
            }

        }
        return max?.toFixed(precision) ?? "";
    }

    public avg(name: string, measurementPoint?: string): string {
        let precision: number = 1;
        let sum: number = 0;
        let count: number = 0;
        for (const v of this.values) {
            if (v.name == name && v.value) {
                if (measurementPoint && v.measurementPoint != measurementPoint) {
                    continue;
                }
                precision = Math.max(UiHelper.getPrecisionForUnit(v.unit), precision);
                sum += v.value;
                count++;
            }
        }
        return count > 0 ? (sum / count).toFixed(precision) : "";
    }

    public stdDev(name: string, measurementPoint?: string): string {
        let precision: number = 1;
        const values: Array<number> = [];
        for (const v of this.values) {
            if (v.name === name && v.value !== undefined) {
                if (measurementPoint && v.measurementPoint !== measurementPoint) {
                    continue;
                }
                precision = Math.max(UiHelper.getPrecisionForUnit(v.unit), precision);
                values.push(v.value);
            }
        }

        if (values.length > 0) {
            // Step 1: Calculate the mean (average)
            let sum: number = 0;
            for (const value of values) {
                sum += value;
            }
            const mean: number = sum / values.length;

            // Step 2: Compute squared differences from the mean
            let squareDiffsSum: number = 0;
            for (const value of values) {
                const diff: number = value - mean;
                squareDiffsSum += diff * diff;
            }

            // Step 3: Calculate the mean of these squared differences
            const avgSquareDiff: number = squareDiffsSum / values.length;

            // Step 4: Take the square root
            return Math.sqrt(avgSquareDiff).toFixed(precision);
        }
        return "";
    }
}
