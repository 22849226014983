import { SchemaMetaData } from "../schema-meta-data";
import { JobV1 } from "./job.v1";

/**
 * This class defines the json structure for an exported job template in version 1.
 */
export class JobFileV1 {

    public metaData: SchemaMetaData|undefined;
    public job: JobV1|undefined;
}
