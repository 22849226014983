import { Component, Input } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { DateTime } from "luxon";

import { BaseComponent } from "../../../base/components/base/base-component";
import { CloneUtil } from "../../../base/helper/clone-util";
import { Chart } from "../../datamodel/chart";
import { ChartTypes } from "../../datamodel/chart-types";
import { Measurement } from "../../datamodel/measurement";
import { SpecialColumns } from "../../datamodel/special-columns";
import { CsvSettings } from "../../entities/csv/csv-settings";
import { UiHelper } from "../../helpers/ui-helper";
import { DialogService } from "../../services/dialog/dialog.service";
import { AppSettings } from "../../services/settings/app-settings";
import { SettingsService } from "../../services/settings/settings-service";

/**
 *
 */
@Component({
    selector: "app-csv-export-settings",
    templateUrl: "./csv-export-settings.component.html",
    styleUrls: ["./csv-export-settings.component.scss"]
})
export class CsvExportSettingsComponent extends BaseComponent {
    constructor(
        private readonly dialogService: DialogService,
        private readonly translateService: TranslateService,
        private readonly modalController: ModalController,
        private readonly settingsService: SettingsService
    ) {
        super();
    }

    protected uiHelper: typeof UiHelper = UiHelper;

    @Input()
    public csvSettings: CsvSettings = new CsvSettings();

    protected gonioCurveActive: boolean = false;

    protected allMeasurements: Array<Measurement> = [];
    protected localTimezoneOffset: string = `${DateTime.local().toFormat("ZZ")}${(DateTime.local().offsetNameShort ? `, ${DateTime.local().offsetNameShort}` : "")}`;

    protected override componentInit(): void {
        for (const measurement of this.csvSettings.sourceMeasurements ?? []) {
            this.allMeasurements.push(measurement);
            if (!this.gonioCurveActive && measurement.charts.some((chart: Chart) => chart.type == ChartTypes.gonio)) {
                this.gonioCurveActive = true;
            }
        }

        if (!this.gonioCurveActive && this.csvSettings.sourceJob?.measuredPoints) {
            for (const measuredPoint of this.csvSettings.sourceJob.measuredPoints) {
                for (const measurement of measuredPoint.measurements) {
                    this.allMeasurements.push(measurement);
                    if (!this.gonioCurveActive && measurement.charts.some((chart: Chart) => chart.type == ChartTypes.gonio)) {
                        this.gonioCurveActive = true;
                    }
                }
            }
        }

        this.allMeasurements = [...this.allMeasurements].sort((a: Measurement, b: Measurement) => (a.localId ?? 0) - (b.localId ?? 0));
        this.csvSettings.selectedMeasurements = [...this.allMeasurements];
    }

    protected override componentDestroy(): void {
        // Do nothing
    }

    protected close(): void {
        this.modalController.dismiss().then();
    }

    public async save(): Promise<void> {
        if (this.csvSettings.delimiter == "," && this.csvSettings.decimalSeparator == ",") {
            this.dialogService.alert(this.translateService.instant("CsvExport.exportSettingsTitle"), this.translateService.instant("CsvExport.commaIssue")).then();
            return;
        }

        this.csvSettings.columns.unshift(...[
            SpecialColumns.localId,
            SpecialColumns.name,
            SpecialColumns.device,
            SpecialColumns.deviceSerialNumber,
            SpecialColumns.timestamp
        ]);

        this.csvSettings.selectedMeasurements.sort((a: Measurement, b: Measurement) => (a.localId ?? 0) - (b.localId ?? 0));
        this.csvSettings.delimiter = this.csvSettings.delimiter == "\\t" ? "\t" : this.csvSettings.delimiter;

        this.modalController.dismiss(this.csvSettings).then();

        // Update settings
        const settings: AppSettings = await this.settingsService.loadSettings();
        const defaultSettings: CsvSettings = new CsvSettings();
        settings.csvSettings = CloneUtil.clone(this.csvSettings);
        settings.csvSettings.filename = defaultSettings.filename;
        settings.csvSettings.columns = defaultSettings.columns;
        settings.csvSettings.sourceMeasurements = defaultSettings.sourceMeasurements;
        settings.csvSettings.sourceJob = defaultSettings.sourceJob;
        settings.csvSettings.selectedMeasurements = defaultSettings.selectedMeasurements;
        this.settingsService.saveSettings(settings).then();
    }

    public isChecked(measurement: Measurement): boolean {
        return this.csvSettings.selectedMeasurements.includes(measurement);
    }

    public checkMeasurement(measurement: Measurement, event: CustomEvent): void {
        this.csvSettings.selectedMeasurements = this.csvSettings.selectedMeasurements.filter((m: Measurement) => m != measurement);
        if (event.detail.checked) {
            this.csvSettings.selectedMeasurements.push(measurement);
        }
    }
}
