import { Component } from "@angular/core";

import { BaseComponent } from "../../../base/components/base/base-component";
import { MenuItem } from "../../../base/services/navigation/menu-item";
import { NavigationService } from "../../../base/services/navigation/navigation.service";
import { AppService } from "../../services/app/app.service";

/**
 *
 */
@Component({
    selector: "app-tabs",
    templateUrl: "./tabs.page.html",
    styleUrls: ["./tabs.page.scss"]
})
export class TabsPage extends BaseComponent {
    public constructor(
        private readonly appService: AppService,
        private readonly navigationService: NavigationService
    ) {
        super();
        this.items = this.navigationService.menuItems;
    }

    public items: Array<MenuItem>;

    protected componentInit(): void {
        // Do nothing for now
    }

    protected componentDestroy(): void {
        // Do nothing for now
    }

    protected get showNavBar(): boolean {
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        return screen.width <= 810;
    };

    public preNavigate(menuItem: MenuItem): void {
        if (menuItem.clickHandler) {
            menuItem.clickHandler();
        }
    }
}
