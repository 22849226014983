import { Component, ElementRef, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ModalController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";

import { BaseComponent } from "../../../base/components/base/base-component";
import { JobTemplate } from "../../datamodel/job-template";
import { OnlineTemplateMetaDto } from "../../entities/online-templates/online-template-meta.dto";
import { JobTemplateImporter } from "../../pages/job-designer/sharing/job-template-importer";
import { JobTemplateService } from "../../services/job-template/job-template.service";
import { ErrorDialogComponent } from "../error-dialog/error-dialog.component";
import { ErrorMessage } from "../error-dialog/error-message";

/**
 * Component to browse online templates.
 */
@Component({
    selector: "app-template-browser",
    templateUrl: "./template-browser.component.html",
    styleUrls: ["./template-browser.component.scss"]
})
export class TemplateBrowserComponent extends BaseComponent {
    constructor(
        private readonly jobTemplateService: JobTemplateService,
        private readonly jobTemplateImporter: JobTemplateImporter,
        private readonly translateService: TranslateService,
        private readonly modalController: ModalController,
        private readonly snackbar: MatSnackBar,
        private readonly dialog: MatDialog
    ) {
        super();
    }

    @ViewChild("fileSelector")
    private fileSelectorRef?: ElementRef<HTMLInputElement>;

    protected loading: boolean = true;
    protected templateLoading: boolean = false;

    protected templates: Array<OnlineTemplateMetaDto> = [];

    protected override componentInit(): void {
        this.refreshOnlineTemplates().then();
    }

    protected override componentDestroy(): void {
        // Nothing to do
    }

    protected close(): void {
        this.modalController.dismiss().then();
    }

    protected async refreshOnlineTemplates(): Promise<void> {
        this.loading = true;
        this.templates = await this.jobTemplateService.fetchOnlineTemplates();
        this.loading = false;
    }

    public async import(templateMeta: OnlineTemplateMetaDto): Promise<void> {
        if (this.templateLoading || !templateMeta.filename) {
            return;
        }

        try {
            this.templateLoading = true;
            this.snackbar.open(this.translateService.instant("JobTemplateList.importing"), undefined, {
                duration: this.snackbarDuration,
                verticalPosition: this.snackbarVerticalPosition
            });
            templateMeta.status = "loading";
            const templateBlob: Blob|undefined = await this.jobTemplateService.downloadOnlineTemplates(templateMeta.filename);
            if (templateBlob) {
                const file: File = new File([templateBlob], templateMeta.filename, { type: templateBlob.type });
                await this.importFile(file);
            }
            templateMeta.status = "imported";
        } catch (error: Error|any) {
            templateMeta.status = "error";
            templateMeta.error = error.message as string ?? `${error}` ?? "Unable to download template.";
            console.error(error);
        } finally {
            this.templateLoading = false;
            this.close();
        }
    }

    public async importFromFile(): Promise<void> {
        if (!this.fileSelectorRef) {
            return;
        }

        this.fileSelectorRef.nativeElement.onchange = async (event: Event): Promise<void> => {
            const files: FileList|null = (event.target as HTMLInputElement)?.files;
            if (files && files.length > 0) {
                try {
                    await this.importFile(files[0]);
                } finally {
                    if (this.fileSelectorRef) {
                        this.fileSelectorRef.nativeElement.value = "";
                    }
                }
            }
            this.close();
        };
        this.fileSelectorRef.nativeElement.click();
    }

    private async importFile(file: File): Promise<void> {
        try {
            this.snackbar.open(this.translateService.instant("JobTemplateList.importing"), undefined, {
                duration: this.snackbarDuration,
                verticalPosition: this.snackbarVerticalPosition
            });
            const jobTemplate: JobTemplate|undefined = await this.jobTemplateImporter.import(file);
            if (!jobTemplate) {
                // noinspection ExceptionCaughtLocallyJS
                throw new Error("Job template importer returned an empty result.");
            }
            await this.jobTemplateService.save(jobTemplate);
            this.snackbar.open(this.translateService.instant("JobTemplateList.jobTemplateImported"), undefined, {
                duration: this.snackbarDuration,
                verticalPosition: this.snackbarVerticalPosition
            });
        } catch (error: Error|any) {
            this.dialog.open(ErrorDialogComponent, {
                data: {
                    title: "ErrorDialog.importFailed",
                    advice: [
                        "ErrorDialog.adviceContactSupport"
                    ],
                    details: [`${error.message}` ?? ""],
                    severity: "error",
                    buttons: []
                } as ErrorMessage
            });
        }
    }
}
