import { Component, ViewChild } from "@angular/core";
import { FileSharer } from "@byteowls/capacitor-filesharer";
import { Subscription } from "rxjs";

import { BaseComponent } from "../../../base/components/base/base-component";
import { AsyncHelper } from "../../../base/helper/async-helper";
import { BluetoothService } from "../../../base/services/ble/bluetooth.service";
import { CsvExportSettingsComponent } from "../../components/csv-export-settings/csv-export-settings.component";
import { MeasurementTableComponent } from "../../components/measurement-table/measurement-table.component";
import { Measurement } from "../../datamodel/measurement";
import { CsvSettings } from "../../entities/csv/csv-settings";
import { UiHelper } from "../../helpers/ui-helper";
import { CsvExporterService } from "../../services/csv-exporter/csv-exporter.service";
import { DialogService } from "../../services/dialog/dialog.service";
import { QuickModePdf } from "../../services/pdf/documents/quickmode.pdf";
import { SettingsService } from "../../services/settings/settings-service";

/**
 * QuickModeComponent allows the user to do measurements with a bluetooth device without having to create or select a JobTemplate.
 */
@Component({
    selector: "app-quick-mode",
    templateUrl: "./quick-mode.component.html",
    styleUrls: ["./quick-mode.component.scss"]
})
export class QuickModeComponent extends BaseComponent {
    constructor(
        private readonly bluetoothService: BluetoothService,
        private readonly quickModePDF: QuickModePdf,
        private readonly dialogService: DialogService,
        private readonly csvExporterService: CsvExporterService,
        private readonly settingsService: SettingsService
    ) {
        super();
        this.caption = "QuickMode.title";
    }

    protected exportingPdf: boolean = false;
    protected exportingCsv: boolean = false;

    public measurements: Array<Measurement> = [];
    @ViewChild("measurementTableComponent")
    private measurementTableComponent?: MeasurementTableComponent;
    private onDataSubscription?: Subscription;
    private onDeleteMeasurementSubscription?: Subscription;

    protected componentInit(): void {
        this.measurements = this.bluetoothService.measurements;
        this.onDataSubscription = this.bluetoothService.onData.subscribe((measurement: Measurement) => {
            this.newMeasurement(measurement);
            this.measurementTableComponent?.refresh();
        });
        this.onDeleteMeasurementSubscription = this.bluetoothService.onDeleteMeasurement.subscribe((_measurement: Measurement) => {
            this.measurements = this.bluetoothService.measurements;
            this.measurementTableComponent?.refresh();
        });
    }

    protected componentDestroy(): void {
        this.onDataSubscription?.unsubscribe();
        this.onDeleteMeasurementSubscription?.unsubscribe();
    }

    protected override componentEnter(): void {
        this.measurementTableComponent?.refresh();
    }

    protected override componentLeave(): void {
        // Do nothing
    }

    private newMeasurement(_measurement: Measurement): void {
        if (!this.componentIsActive) {
            return;
        }

        this.measurementTableComponent?.refresh();
    }

    public async exportCsv(): Promise<void> {
        let settings: CsvSettings|undefined = (await this.settingsService.loadSettings()).csvSettings ?? new CsvSettings();
        settings.sourceMeasurements = this.measurements;
        settings.columns = [...(UiHelper.measurementsToColumnNames(this.measurements))];

        settings = await this.dialogService.openDialog<CsvSettings>(CsvExportSettingsComponent, { fullscreen: false }, { csvSettings: settings });
        if (settings == null) {
            return;
        }

        try {
            this.exportingCsv = true;
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            await AsyncHelper.sleep(10);
            await this.csvExporterService.export(settings);
        } finally {
            this.exportingCsv = false;
        }
    }

    public async exportPdf(): Promise<void> {
        this.exportingPdf = true;
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        await AsyncHelper.sleep(10);

        try {
            await this.quickModePDF.generatePDF(this.measurements, async (base64Data: string) => {
                try {
                    await FileSharer.share({
                        filename: "measurements.pdf",
                        contentType: "application/pdf",
                        base64Data: base64Data
                    });
                } catch (error) {
                    console.error(error);
                } finally {
                    this.exportingPdf = false;
                }
            });
        } catch (error) {
            console.error(error);
            this.exportingPdf = false;
        }
    }

    public deleteAllMeasurements(): void {
        this.bluetoothService.measurements = [];
        this.measurements = this.bluetoothService.measurements;
    }

    public deleteMeasurement(measurement: Measurement): void {
        this.bluetoothService.deleteMeasurement(measurement);
    }
}
