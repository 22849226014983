import { Photo } from "../../../../datamodel/photo";

/**
 * Export entity for a photo.
 */
export class PhotoV1 {
    public partId?: number;
    public binaryAsDataURL: string|undefined;
    public mimeType?: string;

    public static partialFromEntity(photo: Photo): Partial<PhotoV1> {
        const v1: PhotoV1 = new PhotoV1();
        v1.partId = photo.partId;
        v1.mimeType = photo.mimeType;
        return v1;
    }

    public static toPartialEntity(v1: PhotoV1): Partial<Photo> {
        const entity: Partial<Photo> = {};
        entity.partId = v1.partId;
        entity.mimeType = v1.mimeType;
        return entity;
    }
}
