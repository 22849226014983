import { SchemaMetaData } from "../schema-meta-data";
import { JobTemplateV1 } from "./job-template.v1";
import { MeasuredPointV1 } from "./measured-point.v1";
import { PhotoV1 } from "./photo.v1";

/**
 * Job export container.
 */
export class JobV1 extends JobTemplateV1 {
    public metaData: SchemaMetaData|undefined;

    public measuredPoints: Array<MeasuredPointV1> = [];
    public photos: Array<PhotoV1> = [];
    public jobTemplateId: number|undefined;
}
