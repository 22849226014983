<table *ngIf="highlightLastMeasurement && lastMeasurement" class="lastMeasuredTable">
    <tr *ngIf="lastMeasurement.localId > 0">
        <td>{{'Measurement.localId'|translate}}</td>
        <td>{{uiHelper.numberToLetters(lastMeasurement.localId)}}</td>
    </tr>
    <tr *ngFor="let measuredValue of lastMeasurement.values">
        <td>
            {{"" + measuredValue.name | translate}}
        </td>
        <td>
            {{uiHelper.formatValue(measuredValue)|translate}}
        </td>
        <td>
            {{uiHelper.formatValueUnit(measuredValue)|translate}}
        </td>
    </tr>
</table>

<div class="measurementsList">
    <table #measurementMatTable *ngIf="measurements.length > 0" [dataSource]="measurements" mat-table>
        <ng-container *ngFor="let column of displayedColumns" [matColumnDef]="column">
            <th *matHeaderCellDef [id]="column" mat-header-cell>
                <span *ngIf="column == specialColumns.localId" class="cellHeaderLocalId">{{column|translate}}<br />&nbsp;</span>
                <span *ngIf="column == specialColumns.name" class="cellHeaderName">{{column|translate}}<br />&nbsp;</span>
                <span *ngIf="column == specialColumns.charts" class="cellHeaderCharts">{{column|translate}}<br />&nbsp;</span>
                <span *ngIf="column != specialColumns.localId && column != specialColumns.name && column != specialColumns.comments && column != specialColumns.charts" class="cellHeader">{{measurementTableComponent.getTitleForColumn(column)}}<br />&nbsp;{{measurementTableComponent.getUnitForColumn(column, measurements, true, true)}}</span>
                <span *ngIf="column == specialColumns.comments" class="cellHeaderComment">{{column|translate}}<br />&nbsp;</span>
            </th>

            <td *matCellDef="let measurement" mat-cell>
                <span (click)="editName(measurement)" *ngIf="column == specialColumns.localId" class="cellContentLocalId">
                    <span *ngIf="measurement.localId > 0">{{uiHelper.numberToLetters(measurement.localId)}}</span>
                </span>
                <span (click)="openChart(measurement)" *ngIf="column == specialColumns.charts && measurement.charts.length > 0" class="cellContentCharts">
                    <fa-icon [icon]="appIcons.chart"></fa-icon>
                </span>
                <span (click)="editName(measurement)" *ngIf="column == specialColumns.name" class="cellContentName">
                    {{(measurement.name ? measurement.name : '&nbsp;' )}}
                </span>

                <fa-icon (click)="deleteMeasurement(measurement)" *ngIf="column == specialColumns.actions" [icon]="appIcons.trash" class="cellContent"></fa-icon>

                <span *ngIf="column != specialColumns.localId && column != specialColumns.name && column != specialColumns.actions && column != specialColumns.comments && column != specialColumns.charts" class="cellContent">
                    {{measurementTableComponent.getValueForColumn(column, measurement) | translate}}
                </span>
                <span (click)="editComment(measurement)" *ngIf="column == specialColumns.comments" class="cellContentComment">
                    {{measurementTableComponent.getValueForColumn(column, measurement)}}
                </span>
            </td>
        </ng-container>

        <tr *matHeaderRowDef="displayedColumns" class="tableHeader" mat-header-row></tr>
        <tr *matRowDef="let p; columns: displayedColumns;" mat-row></tr>
    </table>

    <table #statisticsTable *ngIf="showStatistics && measurements.length > 1" [dataSource]="statistics" mat-table>
        <ng-container *ngFor="let column of statisticsColumns" [matColumnDef]="column">
            <th *matHeaderCellDef [id]="column" mat-header-cell class="cellContentSummary">{{measurementTableComponent.getTitleForColumn(column)}}<br />&nbsp;{{measurementTableComponent.getUnitForColumn(column, measurements, true, true)}}</th>
            <td *matCellDef="let statistic" mat-cell class="cellContentSummary">{{getStatisticsValue(statistic, column) | translate}}</td>
        </ng-container>

        <tr *matHeaderRowDef="statisticsColumns" class="tableHeader" mat-header-row></tr>
        <tr *matRowDef="let p; columns: statisticsColumns;" mat-row></tr>
    </table>
</div>

<div *ngIf="measurements.length == 0">
    <mat-card>
        <mat-card-content>{{'QuickMode.noMeasurements' | translate}}</mat-card-content>
    </mat-card>
</div>

