/**
 * A single point in the chart.
 */
export class ChartPoint {
    constructor(xValue: number, yValue: number) {
        this.x = xValue;
        this.y = yValue;
    }

    public x: number = 0;
    public y: number = 0;
}
