import { Component } from "@angular/core";
import { Subscription } from "rxjs";

import { BaseComponent } from "../../../base/components/base/base-component";
import { NavigationService } from "../../../base/services/navigation/navigation.service";
import { TemplateBrowserComponent } from "../../components/template-browser/template-browser.component";
import { JobTemplate } from "../../datamodel/job-template";
import { Property } from "../../datamodel/property";
import { DialogService } from "../../services/dialog/dialog.service";
import { JobTemplateService } from "../../services/job-template/job-template.service";

/**
 * JobListComponent displays all available job templates.
 */
@Component({
    selector: "app-job-template-list",
    templateUrl: "./job-template-list.component.html",
    styleUrls: ["./job-template-list.component.scss"]
})
export class JobTemplateListComponent extends BaseComponent {
    constructor(
        private readonly jobTemplateService: JobTemplateService,
        private readonly navigationService: NavigationService,
        private readonly dialogService: DialogService
    ) {
        super();

        this.refresh().then();
    }

    public displayedColumns: Array<string> = ["id", "name"];
    public jobTemplates: Array<JobTemplate> = [];
    protected readonly jobClass = JobTemplate;
    private demoTemplateInstallerDialogCloseSubscription?: Subscription;
    public filteredJobTemplates: Array<JobTemplate> = [];
    protected searchQuery: string = "";

    protected componentInit(): void {
        // Do nothing
    }

    protected componentDestroy(): void {
        this.demoTemplateInstallerDialogCloseSubscription?.unsubscribe();
    }

    public override componentEnter(): void {
        this.initialize().then();
    }

    private async refresh(): Promise<void> {
        this.jobTemplates = await this.jobTemplateService.listJobTemplates();
    }

    private async initialize(): Promise<void> {
        await this.updateJobTemplates();
    }

    private async updateJobTemplates(): Promise<void> {
        this.jobTemplates = await this.jobTemplateService.listJobTemplates();
        this.applySearchFilter();
    }

    public emptyQuery(): void {
        this.searchQuery = "";
        this.applySearchFilter();
    }

    public applySearchFilter(): void {
        const query: string = this.searchQuery.trim().toLowerCase();

        if (!query) {
            this.filteredJobTemplates = this.jobTemplates;
            return;
        }
        const filtered: Array<JobTemplate> = [];
        const queryAsNumber: number = Number.parseInt(query, 10);

        for (const jobTemplate of this.jobTemplates) {
            const jobTemplateName: string|undefined = Property.findByNameKey(JobTemplate.jobTemplatePropertyName, jobTemplate.properties)?.value?.toLowerCase();
            if ((!Number.isNaN(queryAsNumber) && jobTemplate.id == queryAsNumber) || jobTemplateName?.includes(query)) {
                filtered.push(jobTemplate);
            }
        }

        this.filteredJobTemplates = filtered;
    }

    public navigateTo(path: string): void {
        this.navigationService.navigateForward(path).then();
    }

    public loadJob(job: JobTemplate): void {
        this.navigationService.navigateForward(`job-templates/${job.id}`).then();
    }

    public async importTemplate(): Promise<void> {
        await this.dialogService.openDialog(TemplateBrowserComponent, {fullscreen: false});
        await this.updateJobTemplates();
    }
}
