import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { BleClient, BleDevice } from "@capacitor-community/bluetooth-le";
import type { ScanResult } from "@capacitor-community/bluetooth-le/dist/esm/definitions";
import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";

import { environment } from "../../../../environments/environment";
import { ErrorDialogComponent } from "../../../business/components/error-dialog/error-dialog.component";
import { ErrorMessage } from "../../../business/components/error-dialog/error-message";
import { Measurement } from "../../../business/datamodel/measurement";
import { BackendService } from "../../../business/services/backend/backend-service";
import { DialogService } from "../../../business/services/dialog/dialog.service";
import { SettingsService } from "../../../business/services/settings/settings-service";
import { AsyncHelper } from "../../helper/async-helper";
import { CryptoHelper } from "../../helper/crypto-helper";
import { BluetoothConnection } from "./bluetooth-connection";
import { BluetoothStatus } from "./bluetooth-status";
import { BluetoothDevice } from "./devices/bluetooth.device";
import { CoatingThicknessGauge } from "./devices/concrete/coating-thickness-gauge";
import { Glossmeter } from "./devices/concrete/glossmeter";
import { DeviceCodes } from "./devices/device-codes";
import { DeviceNames } from "./devices/device-names";

/**
 * BluetoothService is the service for the application when communication via BLE is necessary.
 */
@Injectable({
    providedIn: "root"
})
export class BluetoothService {
    constructor(
        public dialog: MatDialog,
        private readonly settingsService: SettingsService,
        private readonly backendService: BackendService,
        private readonly dialogService: DialogService,
        private readonly translateService: TranslateService
    ) {
    }

    public allDevicesStatus: BluetoothStatus = BluetoothStatus.unknown;
    public connected: boolean = false;
    public connections: Array<BluetoothConnection> = [];
    public connectionCount: number = 0;
    public measurements: Array<Measurement> = [];

    public readonly allDevicesStatusChanged: Subject<BluetoothStatus> = new Subject<BluetoothStatus>();
    public readonly onData: Subject<Measurement> = new Subject<Measurement>();
    public readonly onDeleteMeasurement: Subject<Measurement> = new Subject<Measurement>();

    private isInitialized: boolean = false;
    private isUnavailable: boolean = false;

    public scannedDevices: Array<ScanResult> = [];
    public scanning: boolean = false;

    public async initialize(): Promise<boolean|Error> {
        if (this.isInitialized) {
            return true;
        }
        try {
            await BleClient.initialize({ androidNeverForLocation: false });

            this.isUnavailable = false;
            const enabled: boolean = BleClient && await BleClient.isEnabled();
            if (enabled) {
                this.isInitialized = true;
                return true;
            } else {
                return false;
            }
        } catch (error) {
            console.warn(error);
            this.isUnavailable = true;
            return error as Error;
        } finally {
            this.recalculateAllDevicesStatus();
        }
    }

    public async tryConnect(device: BluetoothDevice): Promise<void> {
        if (!this.isInitialized) {
            await BleClient.initialize({ androidNeverForLocation: false });
        }
        await this.disconnect(device);

        let connectedDevice: BleDevice|undefined = undefined;
        try {
            connectedDevice = await BleClient.requestDevice({
                services: device.requiredAdvertisingServices.length ? device.requiredAdvertisingServices : undefined,
                optionalServices: device.requiredServices.length ? device.requiredServices : undefined,
                namePrefix: device.requiredNamePrefix
            });
        } catch (error: Error|any) {
            // User cancelled connect
            console.warn(error);
            return;
        }

        return this.connect(device, connectedDevice);
    }

    public connectThroughRawDevice(connectedDevice: BleDevice, scanResult?: ScanResult): Promise<void> {
        const availableUuids: Array<string> = connectedDevice.uuids?.map((uuid: string) => uuid.toLowerCase()) ?? [];
        availableUuids.push(...scanResult?.uuids?.map((uuid: string) => uuid.toLowerCase()) ?? []);
        let device: BluetoothDevice|undefined;
        if (availableUuids.includes("0000ffe0-0000-1000-8000-00805f9b34fb")) {
            device = new CoatingThicknessGauge(this.backendService, this.settingsService, this.dialogService);
        } else {
            device = new Glossmeter(this.backendService, this.settingsService, this.dialogService, this.translateService);
        }
        device.localName = scanResult?.localName;
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        device.signalStrength = scanResult?.rssi ?? 127;

        return this.connect(device, connectedDevice);
    }

    public async connect(device: BluetoothDevice, connectedDevice: BleDevice): Promise<void> {
        try {
            this.connections.push(new BluetoothConnection(device, BluetoothStatus.connecting));
            this.updateConnectionStatus(device, BluetoothStatus.connecting);
            const status: BluetoothStatus = await device.connect(connectedDevice, (measurement: Measurement) => {
                this.addMeasurement(measurement);
                this.onData.next(measurement);
            }, () => {
                this.disconnect(device).then();
            });

            this.updateConnectionStatus(device, status);
        } catch (exception: Error|any) {
            try {
                await device.disconnect();
                await this.disconnect(device);
            } catch (error) {
                console.warn("Unable to disconnect device", error);
            }
            this.dialog.open(ErrorDialogComponent, {
                data: {
                    title: "ErrorDialog.connectionFailure",
                    advice: [],
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    details: [exception.message],
                    severity: "error",
                    buttons: [{ title: "ErrorDialog.buttonAdditionalInformation", uri: "ble-status" }]
                } as ErrorMessage
            });
            this.updateConnectionStatus(device, BluetoothStatus.disconnected);
        }
    }

    private addMeasurement(measurement: Measurement): void {
        measurement.sortValues();

        let maxIndex: number = 0;
        this.measurements.forEach((m: Measurement) => { maxIndex = Math.max(maxIndex, isNaN(m.localId) || m.localId <= 0 ? 0 : m.localId); });
        measurement.localId = maxIndex + 1;

        this.measurements.unshift(measurement);
    }

    public async disconnect(device: BluetoothDevice): Promise<void> {
        try {
            await device.disconnect();
        } catch (error) {
            console.error(`Unable to disconnect ${device.deviceName}.`);
        } finally {
            this.connections = this.connections.filter((connectedDevice: BluetoothConnection) => connectedDevice.device != device);
            this.updateConnectionStatus(device, BluetoothStatus.disconnected);
        }
        this.recalculateAllDevicesStatus();
    }

    public async disconnectAll(): Promise<void> {
        const allDevices: Array<{ device: BluetoothDevice; status: BluetoothStatus }> = [];
        allDevices.push(...this.connections);
        for (const connectedDevice of allDevices) {
            await this.disconnect(connectedDevice.device);
        }
    }

    private updateConnectionStatus(device: BluetoothDevice, newStatus: BluetoothStatus): void {
        const connectedDevice: BluetoothConnection|undefined = this.connections.find((cd: BluetoothConnection) => cd.device == device);
        if (connectedDevice) {
            connectedDevice.status = newStatus;
        }

        this.recalculateAllDevicesStatus();
    }

    private recalculateAllDevicesStatus(): void {
        this.allDevicesStatus = BluetoothStatus.unknown;
        if (this.isUnavailable) {
            this.allDevicesStatus = BluetoothStatus.unavailable;
        } else if (!this.isInitialized) {
            this.allDevicesStatus = BluetoothStatus.disconnected;
        }
        if (this.allDevicesStatus != BluetoothStatus.unknown) {
            this.connectionCount = 0;
            this.allDevicesStatusChanged.next(this.allDevicesStatus);
            return;
        }

        this.connectionCount = this.connections.filter((connection: BluetoothConnection) => connection.status == BluetoothStatus.connected).length;

        if (this.connections.some((connection: BluetoothConnection) => connection.status == BluetoothStatus.connecting)) {
            this.allDevicesStatus = BluetoothStatus.connecting;
        } else if (this.connections.some((connection: BluetoothConnection) => connection.status == BluetoothStatus.connected)) {
            this.allDevicesStatus = BluetoothStatus.connected;
        }

        if (this.allDevicesStatus == BluetoothStatus.unknown) {
            this.allDevicesStatus = BluetoothStatus.disconnected;
        }
        this.allDevicesStatusChanged.next(this.allDevicesStatus);
    }

    public deleteMeasurement(measurement: Measurement): void {
        this.measurements = this.measurements.filter((m: Measurement) => m.id != measurement.id);
        this.onDeleteMeasurement.next(measurement);
    }

    public async startScan(callback: (result: ScanResult) => void): Promise<void> {
        if (this.scanning) {
            await this.stopScan();
        }
        this.scanning = true;

        this.scannedDevices = [];

        const self: BluetoothService = this;
        function deviceUpdate(result: ScanResult): void {
            const deviceId: string|undefined = result?.device?.deviceId;
            if (deviceId) {
                self.scannedDevices = self.scannedDevices.filter((value: ScanResult) => value.device.deviceId != deviceId);
                self.scannedDevices.push(result);
            }
            callback(result);
        }

        try {
            await BleClient.requestLEScan({ allowDuplicates: true }, deviceUpdate);
        } catch (error) {
            console.info(error);

            if (!environment.fakeScan) {
                console.info("Scanning not available, falling back to manual selection.");
                // It's not available on this platform. Add the clients to use the fallback method:
                deviceUpdate({ device: { name: DeviceNames.glossmeter, deviceId: DeviceCodes.glossmeter } as BleDevice, uuids: ["fallback"] });
                deviceUpdate({ device: { name: DeviceNames.thicknessGauge, deviceId: DeviceCodes.thicknessGauge } as BleDevice, uuids: ["fallback"] });
            } else {
                await this.fakeScan(deviceUpdate);
            }

        }
    }

    private async fakeScan(callback: (result: ScanResult) => void): Promise<void> {
        console.info("Starting fake scanner...");
        let count: number = 0;
        while (this.scanning) {
            callback({
                device: {
                    name: `Device ${++count}`,
                    deviceId: CryptoHelper.getUUID()
                } as BleDevice,
                uuids: [Glossmeter.measurementService],
                localName: `Device ${count} Local Name`,
                // eslint-disable-next-line @typescript-eslint/no-magic-numbers
                txPower: Math.random() * 100 - 70
            });
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            await AsyncHelper.sleep(1000);
        }
    }

    public async stopScan(): Promise<void> {
        this.scanning = false;
        await BleClient.stopLEScan();
    }
}

