<input #photoSelect accept="image/png, image/jpeg" class="notRendered" type="file" />

<mat-card-content>
    <div class="gallery">
        <mat-card *ngFor="let photo of photos">
            <img [src]="photoBinaries[photo.binaryId!]" alt="photo" class="photo" fill="width">
            <button (click)="removePhoto(photo)" class="removeButton" color="warn" mat-mini-fab>
                <fa-icon [icon]="appIcons.genericDelete"></fa-icon>
            </button>
        </mat-card>
    </div>

    <div class="buttons">
        <button (click)="takePhoto()" class="addButton" color="primary" mat-flat-button>
            <fa-icon [icon]="appIcons.addPhoto"></fa-icon>
            <span>{{'JobMode.newPhoto' | translate}}</span>
        </button>
        <button (click)="addChart()" class="addButton" color="primary" mat-flat-button *ngIf="chartsCount > 0">
            <fa-icon [icon]="appIcons.addChart"></fa-icon>
            <span *ngIf="chartsCount == 1">{{'Chart.addChart' | translate}}</span>
            <span *ngIf="chartsCount > 1">{{'Chart.addCharts' | translate}}</span>
        </button>
    </div>
</mat-card-content>
