import { CryptoHelper } from "../../base/helper/crypto-helper";

/**
 * Base class for all entities.
 */
export abstract class AbstractEntity {
    public id: number|undefined;
    public correlationId: string|undefined = CryptoHelper.getUUID();
    public version: number|undefined;
}
