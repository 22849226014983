<ion-content [fullscreen]="true" class="componentRoot">
    <ion-header>
        <ion-toolbar>
            <ion-title size="large">{{'DeviceConnector.title'| translate}}</ion-title>
            <ion-buttons slot="end">
                <ion-button (click)="close()" class="closeButton"><fa-icon [icon]="appIcons.dialogClose"></fa-icon></ion-button>
            </ion-buttons>
        </ion-toolbar>
    </ion-header>

    <mat-action-list>
        <button (click)="connectToDevice(device)" *ngFor="let device of filteredDevices" mat-list-item>
            <fa-icon [icon]="uiHelper.txPowerToIcon(device.txPower) ?? appIcons.bluetoothDeviceIcon" matListItemIcon></fa-icon>
            <span matListItemTitle>{{device.localName ?? device.device.name ?? device.device.deviceId}}</span>
            <span matListItemMeta>
                <fa-icon [icon]="appIcons.clickableListItemIcon"></fa-icon>
            </span>
        </button>
    </mat-action-list>
</ion-content>
