import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

import { environment } from "../../../../environments/environment";
import { AppIcons } from "../../../app-icons";
import { readFileAsDataUrlAsync } from "../../../base/helper/file-reader-helper";
import { NavigationService } from "../../../base/services/navigation/navigation.service";
import { SubscriptionDto } from "../../../generated/api";
import { db } from "../../db";
import { AppService } from "../../services/app/app.service";
import { AppState } from "../../services/app/app-state";
import { DialogService } from "../../services/dialog/dialog.service";
import { LoggingService } from "../../services/logging/logging.service";
import { ReportService } from "../../services/report/report.service";
import { AppSettings } from "../../services/settings/app-settings";
import { SettingsService } from "../../services/settings/settings-service";
import { SubscriptionService } from "../../services/subscription/subscription.service";

/**
 * SettingsComponent allows the user to configure the application for his needs.
 */
@Component({
    selector: "app-settings",
    templateUrl: "./settings.component.html",
    styleUrls: ["./settings.component.scss"]
})
export class SettingsComponent implements OnInit {

    constructor(
        private readonly appService: AppService,
        private readonly translateService: TranslateService,
        private readonly loggingService: LoggingService,
        private readonly settingsService: SettingsService,
        private readonly dialogService: DialogService,
        private readonly subscriptionService: SubscriptionService,
        private readonly navigationService: NavigationService,
        private readonly reportService: ReportService
    ) {
        this.languageBacking = this.translateService.currentLang;
        this.appState = this.appService.appState;
        this.logs = this.loggingService.messages;
    }

    public readonly appIcons: typeof AppIcons = AppIcons;
    public readonly appState: AppState;
    private readonly languageBacking: string;
    public logPanelOpenState: boolean = false;
    public logs: Array<any> = [];
    private personalization: AppSettings|undefined;

    @ViewChild("logoSelect")
    private logoSelectRef?: ElementRef<HTMLInputElement>;

    public logo: string|undefined;

    public get thicknessUnit(): string {
        if (this.personalization?.lengthUnit) {
            return this.personalization.lengthUnit;
        }
        return "µm";
    }

    public set thicknessUnit(value: string) {
        if (this.personalization) {
            this.personalization.lengthUnit = value;
            this.settingsService.saveSettings(this.personalization).then();
        }
    }

    public get companyName(): string {
        if (this.personalization?.companyName) {
            return this.personalization.companyName;
        }
        return "";
    }

    public set companyName(value: string) {
        if (this.personalization) {
            this.personalization.companyName = value;
            this.settingsService.saveSettings(this.personalization).then();
        }
    }

    public get companyAddress(): string {
        if (this.personalization?.companyAddress) {
            return this.personalization.companyAddress;
        }
        return "";
    }

    public set companyAddress(value: string) {
        if (this.personalization) {
            this.personalization.companyAddress = value;
            this.settingsService.saveSettings(this.personalization).then();
        }
    }

    public get email(): string {
        if (this.personalization?.email) {
            return this.personalization.email;
        }
        return "";
    }

    public set email(value: string) {
        if (this.personalization) {
            this.personalization.email = value;
            this.settingsService.saveSettings(this.personalization).then();
        }
    }

    public get website(): string {
        if (this.personalization?.website) {
            return this.personalization.website;
        }
        return "";
    }

    public set website(value: string) {
        if (this.personalization) {
            this.personalization.website = value;
            this.settingsService.saveSettings(this.personalization).then();
        }
    }

    public get phone(): string {
        if (this.personalization?.phone) {
            return this.personalization.phone;
        }
        return "";
    }

    public set phone(value: string) {
        if (this.personalization) {
            this.personalization.phone = value;
            this.settingsService.saveSettings(this.personalization).then();
        }
    }

    public get language(): string {
        return this.languageBacking;
    }

    public set language(value: string) {
        if (this.personalization) {
            this.personalization.language = value;
            this.settingsService.saveSettings(this.personalization).then();
        }

        this.translateService.use(value);
    }

    public get licenseCheckEnabled(): boolean {
        return this.settingsService.licenseCheckEnabled;
    }

    protected navigateToLicensePage(): void {
        this.navigationService.navigateForward("license").then();
    }

    public uploadLogo(): void {
        if (this.logoSelectRef?.nativeElement) {
            this.logoSelectRef.nativeElement.onchange = async (event: Event): Promise<void> => {
                const files: FileList|null = (event.target as HTMLInputElement)?.files;
                if (files && files.length > 0) {
                    if (this.personalization) {
                        this.personalization.logoAsDataUrl = await readFileAsDataUrlAsync(files[0]);
                        this.settingsService.saveSettings(this.personalization).then(() => {
                            this.refresh();
                        });
                    }
                }
            };
            this.logoSelectRef.nativeElement.click();
        }
    }

    public ngOnInit(): void {
        this.refresh();
    }

    public installApp(): void {
        this.appService.installPwa();
    }

    public async deleteAllData(): Promise<void> {
        const result: boolean = await this.dialogService.openDeleteDialog("Settings.confirmDialogDeleteAllDataTitle", "Settings.confirmDialogDeleteAllDataDescription", "Settings.confirmDeleteAllDataPassword", "Settings.confirmDeleteAllDataLabel");

        if (result) {
            await db.jobs.clear();
            await db.jobTemplates.clear();
            await db.images.clear();
            await db.parts.clear();
            await db.personalization.clear();
            this.refresh();
        }
    }

    public refresh(): void {
        this.logs = this.loggingService.messages;
        this.settingsService.loadSettings().then((personalization: AppSettings) => {
            this.personalization = personalization;
            this.logo = this.personalization.logoAsDataUrl;
        });
    }

    public enableLicenseCheck(enabled: boolean): void {
        this.settingsService.licenseCheckEnabled = enabled;
    }

    public async removeLogo(): Promise<void> {
        const result: boolean = await this.dialogService.openDeleteDialog("Settings.confirmDialogRemoveLogoTitle", "Settings.confirmDialogDeleteAllDataDescription");
        if (result) {
            if (this.personalization) {
                this.personalization.logoAsDataUrl = undefined;
                this.settingsService.saveSettings(this.personalization).then(() => {
                    this.refresh();
                });
            }
        }
    }

    public openPrivacyPolicy(): void {
        window.open(environment.privacyPolicyUrl, "_blank");
    }

    public async reportError(): Promise<void> {
        try {
            let text: string|undefined = await this.dialogService.prompt(this.translateService.instant("Settings.reportError"), this.translateService.instant("Settings.reportErrorPromptDescription"));
            if (text === undefined) {
                return;
            }

            if (text) {
                text = `# Message\n${text}\n\n`;
            }

            text += "# Logs\n";
            for (const message of this.loggingService.messages) {
                text += `${JSON.stringify(message)}\n`;
            }

            const settings: AppSettings = await this.settingsService.loadSettings();
            text += `\n# Settings\n${JSON.stringify(settings)}\n`;

            const subscriptions: Array<SubscriptionDto> = await this.subscriptionService.getAllSubscriptions();
            text += "\n# Subscriptions:\n";
            for (const subscription of subscriptions) {
                text += `${JSON.stringify(subscription)}\n`;
            }

            const sent: boolean = await this.reportService.sendReport(text, true);
            if (sent) {
                this.dialogService.toast("success", this.translateService.instant("Settings.reportSent"));
            }
        } catch (error: Error|any) {
            console.warn(error);
        }
    }
}
