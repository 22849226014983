import { CryptoHelper } from "../../../../../base/helper/crypto-helper";
import { MeasurementPoint } from "../../../../datamodel/measurement-point";

/**
 *
 */
export class MeasurementPointV1 {
    public id?: string;
    public order: number = 0;
    public x: number = 0;
    public y: number = 0;

    public static fromEntity(entity: MeasurementPoint): MeasurementPointV1 {
        const v1: MeasurementPointV1 = new MeasurementPointV1();
        v1.id = entity.id ?? CryptoHelper.getUUID();
        v1.order = entity.order ?? 0;
        v1.x = entity.x ?? 0;
        v1.y = entity.y ?? 0;
        return v1;
    };

    public static toEntity(v1: MeasurementPointV1): MeasurementPoint {
        const entity: MeasurementPoint = new MeasurementPoint();
        entity.id = v1.id ?? CryptoHelper.getUUID();
        entity.order = v1.order ?? 0;
        entity.x = v1.x ?? 0;
        entity.y = v1.y ?? 0;
        return entity;
    }
}
