import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { EventType, NavigationEnd, Router } from "@angular/router";
import { IconDefinition } from "@fortawesome/pro-regular-svg-icons";
import { ModalController, NavController, Platform } from "@ionic/angular";
import { Subscription } from "rxjs";

import { AppIcons } from "../../../app-icons";
import { BluetoothService } from "../../../base/services/ble/bluetooth.service";
import { BluetoothStatus } from "../../../base/services/ble/bluetooth-status";
import { UiHelper } from "../../helpers/ui-helper";
import { BleStatusComponent } from "../ble-status/ble-status.component";

/**
 * Menubar for every page.
 */
@Component({
    selector: "app-menubar",
    templateUrl: "./menubar.component.html",
    styleUrls: ["./menubar.component.scss"]
})
export class MenubarComponent implements OnInit, OnDestroy {
    constructor(
        private readonly bluetoothService: BluetoothService,
        private readonly router: Router,
        private readonly navController: NavController,
        platform: Platform,
        private modalController: ModalController
    ) {
        this.bluetoothStatus = this.bluetoothService.allDevicesStatus;
        this.isiOS = platform.is("ios");
    }

    @Input()
    public hideTitleOniOS = true;

    @Input()
    public hideBleStatus = false;

    @Input()
    public header: string = "";

    @Input()
    public headerUntranslated: string = "";

    @Input()
    public showBackButton: boolean = true;

    public backButtonIcon: string|undefined = undefined;

    public bluetoothStatusEnum: typeof BluetoothStatus = BluetoothStatus;

    public isiOS: boolean;

    public bluetoothStatus: BluetoothStatus;
    public connectionCount: number = 0;

    protected readonly appIcons: typeof AppIcons = AppIcons;
    private connectionStatusSubscription?: Subscription;
    private routerEventsSubscription?: Subscription;

    public ngOnInit(): void {
        const self: MenubarComponent = this;
        this.connectionStatusSubscription = this.bluetoothService.allDevicesStatusChanged.subscribe((next: BluetoothStatus) => {
            self.bluetoothStatus = next;
            self.connectionCount = self.bluetoothService.connectionCount;
        });
        this.bluetoothStatus = this.bluetoothService.allDevicesStatus;
        this.connectionCount = this.bluetoothService.connectionCount;

        this.routerEventsSubscription = this.router.events.subscribe(this.routerEventsUpdated.bind(self));
    }

    public ngOnDestroy(): void {
        this.backButtonIcon = undefined;
        this.connectionStatusSubscription?.unsubscribe();
        this.routerEventsSubscription?.unsubscribe();
    }

    public async openBleStatusPage(): Promise<void> {
        const modal: HTMLIonModalElement = await this.modalController.create({
            component: BleStatusComponent,
            presentingElement: document.querySelector(".ion-page") as HTMLElement
        });

        await modal.present();
    }

    public statusToIcon(status: BluetoothStatus): IconDefinition {
        return UiHelper.getBluetoothStatusIcon(status);
    }

    public backStart(): void {
        this.backButtonIcon = "hourglass-outline";
    }

    public routerEventsUpdated(event: any): void {
        const navigationEnd: NavigationEnd|undefined = event as NavigationEnd;
        if (navigationEnd && navigationEnd.type == EventType.NavigationEnd) {
            this.backButtonIcon = undefined;
        }
    }
}
