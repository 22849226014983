import { Component, NgZone, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { App, URLOpenListenerEvent } from "@capacitor/app";
import { TranslateService } from "@ngx-translate/core";
import { DateTime } from "luxon";
import { register } from "swiper/element/bundle";

import { environment } from "../environments/environment";
import { EventHelper } from "./base/helper/event-helper";
import { AppUpdateService } from "./base/services/app-update/app-update.service";
import { BluetoothService } from "./base/services/ble/bluetooth.service";
import { TimeService } from "./base/services/time/time.service";
import { Timer } from "./base/services/time/timer";
import { UiHelper } from "./business/helpers/ui-helper";
import { AppService } from "./business/services/app/app.service";
import { LoggingService } from "./business/services/logging/logging.service";
import { MigrationService } from "./business/services/migration/migration.service";
import { ReportService } from "./business/services/report/report.service";
import { AppSettings } from "./business/services/settings/app-settings";
import { SettingsService } from "./business/services/settings/settings-service";
import { SubscriptionService } from "./business/services/subscription/subscription.service";
import { SubscriptionDto } from "./generated/api";

register();

/**
 * AppComponent.
 */
@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit {
    constructor(
        private readonly appService: AppService,
        private readonly appUpdateService: AppUpdateService,
        private readonly bluetoothService: BluetoothService,
        private readonly timeService: TimeService,
        private readonly settingsService: SettingsService,
        private readonly migrationService: MigrationService,
        private readonly loggingService: LoggingService,
        private readonly reportService: ReportService,
        private readonly subscriptionService: SubscriptionService,

        private readonly router: Router,
        private readonly zone: NgZone,

        translateService: TranslateService
    ) {
        this.initializeLogging();
        console.info("Initializing app...");

        UiHelper.translateService = translateService;

        translateService.setDefaultLang("en");
        this.settingsService.loadSettings().then((personalization: AppSettings) => {
            translateService.use(personalization.language ?? "en");
        });

        this.initializeDeepLinks();
    }

    private postInitTimer?: Timer;
    private lastLogSent: DateTime|undefined = undefined;

    private initializeLogging(): void {
        this.loggingService.intercept();

        const self: AppComponent = this;
        this.loggingService.unhandledPromiseException.subscribe(self.errorLogged.bind(self));
        this.loggingService.unhandledTextMessageException.subscribe(self.errorLogged.bind(self));
        this.loggingService.unhandledAppException.subscribe(self.errorLogged.bind(self));
        this.loggingService.unhandledServerException.subscribe(self.errorLogged.bind(self));
    }

    private async errorLogged(_error: Error|string): Promise<void> {
        try {
            const currentTime: DateTime = DateTime.utc();
            if (this.lastLogSent && currentTime.diff(this.lastLogSent, "minutes").minutes < 1) {
                console.info("Skipping error report.");
                return;
            }
            this.lastLogSent = currentTime;

            let text: string = "# Logs\n";
            for (const message of this.loggingService.messages) {
                text += `${JSON.stringify(message)}\n`;
            }

            const settings: AppSettings = await this.settingsService.loadSettings();
            text += `\n# Settings\n${JSON.stringify(settings)}\n`;

            const subscriptions: Array<SubscriptionDto> = await this.subscriptionService.getAllSubscriptions();
            text += "\n# Subscriptions:\n";
            for (const subscription of subscriptions) {
                text += `${JSON.stringify(subscription)}\n`;
            }

            await this.reportService.sendReport(text);
        } catch (e) {
            console.warn("Error reporting error", e);
        }
    }

    private initializeDeepLinks(): void {
        App.addListener("appUrlOpen", (event: URLOpenListenerEvent) => {
            this.zone.run(() => {
                const url: string = event.url as string;
                if (url.includes("id.rhopointservice.com")) {
                    const id: string|undefined = url.split("rhopointservice.com").pop();
                    if (id) {
                        this.router.navigateByUrl(`id${id}`).then();
                    }
                }
            });
        });
    }

    protected get showMenu(): boolean {
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        return screen.width > 810;
    };

    public async ngOnInit(): Promise<void> {
        await this.initialize();
    }

    private async initialize(): Promise<void> {
        await this.appUpdateService.initialize();

        await this.appService.initialize();

        await this.migrationService.runMigration();

        await this.bluetoothService.initialize();

        const personalization: AppSettings = await this.settingsService.loadSettings();
        UiHelper.lengthUnit = personalization.lengthUnit ?? "µm";

        this.appService.notifyAppCompletelyInitialized();

        this.postInitTimer = this.timeService.spawnTimer(environment.postAppInitDelay);
        EventHelper.subscribe(this.postInitTimer.elapsed, this.postInitApp, this);
        this.postInitTimer.start();
    }

    private postInitApp(): void {
        this.postInitTimer!.stop();

        this.appUpdateService.startUpdateCheck(environment.appUpdateCheckInterval, true);
        console.info("Post init done.");
    }
}
