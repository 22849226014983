import { Component } from "@angular/core";
import { ModalController } from "@ionic/angular";

import { BaseComponent } from "../../../base/components/base/base-component";
import { Job } from "../../datamodel/job";
import { JobTemplate } from "../../datamodel/job-template";
import { JobTemplateService } from "../../services/job-template/job-template.service";

/**
 * SelectJobTemplateComponent is used to select a JobTemplate from which a new job will be generated.
 */
@Component({
    selector: "app-select-job-template",
    templateUrl: "./select-job-template.component.html",
    styleUrls: ["./select-job-template.component.scss"]
})
export class SelectJobTemplateComponent extends BaseComponent {
    public constructor(
        private jobTemplateService: JobTemplateService,
        private modalController: ModalController
    ) {
        super();
    }

    protected jobTemplates: Array<JobTemplate> = [];
    protected readonly jobTemplateClass: typeof Job = Job;

    protected componentInit(): void {
        this.initialize().then();
    }

    private async initialize(): Promise<void> {
        this.jobTemplates = await this.jobTemplateService.listJobTemplates();
    }

    protected componentDestroy(): void {
        // Do nothing for now
    }

    public async select(jobTemplate: JobTemplate): Promise<void> {
        await this.modalController.dismiss(jobTemplate);
    }

    public async cancel(): Promise<void> {
        await this.modalController.dismiss();
    }
}
