<app-menubar [showBackButton]="false" header="JobTemplateList.title">
    <button (click)="importTemplate()" class="iconButton" color="primary" mat-stroked-button>
        <fa-icon [icon]="appIcons.fileIn"></fa-icon>
        <span>{{'JobTemplateList.importTemplate' | translate}}</span>
    </button>
</app-menubar>

<ion-content [fullscreen]="true">
    <ion-header collapse="condense">
        <ion-toolbar>
            <ion-title size="large">{{'JobTemplateList.title'| translate}}</ion-title>
        </ion-toolbar>
    </ion-header>

    <div class="content">
        <button (click)="navigateTo('job-templates/')" class="iconButton bigButton" color="primary" mat-flat-button>
            <fa-icon [icon]="appIcons.genericAdd" matListItemIcon></fa-icon>
            <span>{{'JobTemplateList.newJob' | translate}}</span>
        </button>

        <div>
            <mat-form-field *ngIf="jobTemplates.length > 0" appearance="outline" class="search">
                <mat-label>{{'Generic.search' | translate}}</mat-label>
                <input (input)="applySearchFilter()" [(ngModel)]="searchQuery" matInput>
                <button (click)="emptyQuery()" *ngIf="searchQuery" aria-label="Clear" mat-button matSuffix>
                    <fa-icon [icon]="appIcons.dialogClose"></fa-icon>
                </button>
            </mat-form-field>
        </div>
        <p *ngIf="jobTemplates.length > 0 && filteredJobTemplates.length <= 0">{{'JobTemplateList.notFound' | translate}} "{{searchQuery}}".</p>

        <mat-action-list>
            <button (click)="loadJob(jobTemplate)" *ngFor="let jobTemplate of filteredJobTemplates" mat-list-item>
                <fa-icon [icon]="appIcons.jobModeIcon" matListItemIcon></fa-icon>
                <span matListItemTitle>{{jobClass.getJobTemplateDisplayName(jobTemplate)}}</span>
                <fa-icon [icon]="appIcons.clickableListItemIcon" matListItemMeta></fa-icon>
            </button>
        </mat-action-list>
    </div>
</ion-content>
