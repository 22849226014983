<app-menubar [showBackButton]="true" header="LicensePage.title">
</app-menubar>

<ion-content [fullscreen]="true" class="content ion-padding">
    <ion-header collapse="condense">
        <ion-toolbar>
            <ion-title size="large">{{'LicensePage.title'|translate}}</ion-title>
        </ion-toolbar>
    </ion-header>

    <div *ngIf="subscriptionRows.length === 0">
        <span>{{"LicensePage.noLicense" | translate}}</span>
    </div>

    <div class="tableContainer">
        <table #infoTable *ngIf="subscriptionRows.length > 0" [dataSource]="subscriptionRows" mat-table>
            <ng-container matColumnDef="loading">
                <th *matHeaderCellDef mat-header-cell style="width:1px;"></th>
                <td *matCellDef="let row" mat-cell style="width:1px;">
                    <mat-spinner *ngIf="row.loading" [diameter]="20"></mat-spinner>
                </td>
            </ng-container>

            <ng-container matColumnDef="serialNumber">
                <th *matHeaderCellDef mat-header-cell>{{'LicensePage.serialNumber' | translate}}</th>
                <td *matCellDef="let row" mat-cell>
                    <div class="serial">
                        {{row.serialNumber}}
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="status">
                <th *matHeaderCellDef mat-header-cell>{{'LicensePage.status' | translate}}</th>
                <td *matCellDef="let row" mat-cell>
                    <fa-icon *ngIf="row.active" [icon]="appIcons.checkSuccess" class="iconActive"></fa-icon>
                    <fa-icon *ngIf="!row.active" [icon]="appIcons.checkFailed" class="iconInactive"></fa-icon>
                    {{row.status}}
                </td>
            </ng-container>

            <ng-container matColumnDef="termDates">
                <th *matHeaderCellDef mat-header-cell>{{'LicensePage.termDates' | translate}}</th>
                <td *matCellDef="let row" mat-cell>
                    <div>
                        {{row.currentTermStarts}} -
                    </div>
                    <div>
                        {{row.currentTermEnds}}
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="interval">
                <th *matHeaderCellDef mat-header-cell>{{'LicensePage.interval' | translate}}</th>
                <td *matCellDef="let row" mat-cell>{{row.interval}} <span *ngIf="row.intervalUnit">{{row.intervalUnit[0]}}</span></td>
            </ng-container>

            <ng-container matColumnDef="planName">
                <th *matHeaderCellDef mat-header-cell>{{'LicensePage.planName' | translate}}</th>
                <td *matCellDef="let row" mat-cell>{{row.planName}}</td>
            </ng-container>

            <ng-container matColumnDef="expireDate">
                <th *matHeaderCellDef mat-header-cell>{{'LicensePage.expireDate' | translate}}</th>
                <td *matCellDef="let row" mat-cell>{{row.expiresAt}}</td>
            </ng-container>

            <ng-container matColumnDef="billingDate">
                <th *matHeaderCellDef mat-header-cell>{{'LicensePage.billingDate' | translate}}</th>
                <td *matCellDef="let row" mat-cell>
                    {{row.nextBilling}}
                </td>
            </ng-container>

            <ng-container matColumnDef="scheduledCancellationDate">
                <th *matHeaderCellDef mat-header-cell>{{'LicensePage.scheduledCancellationDate' | translate}}</th>
                <td *matCellDef="let row" mat-cell>{{row.scheduledCancellationDate}}</td>
            </ng-container>

            <tr *matHeaderRowDef="displayedColumns" class="tableHeader" mat-header-row></tr>
            <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
        </table>
    </div>

    <div class="actions">
        <button (click)="refresh()" [disabled]="loading" mat-stroked-button>
            <fa-icon [icon]="appIcons.genericRefresh"></fa-icon>
            {{'Settings.refresh' | translate}}
        </button>
        <button (click)="openPortal()" [disabled]="loading" mat-stroked-button>
            <fa-icon [icon]="appIcons.license"></fa-icon>
            {{'LicensePage.manageLicense' | translate}}
        </button>
    </div>
</ion-content>
