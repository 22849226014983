import { MeasuredValue } from "../../../../datamodel/measured-value";

/**
 *
 */
export class MeasuredValueV1 {
    public name?: string;
    public value?: string;
    public unit?: string;

    public static fromEntity(value: MeasuredValue): MeasuredValueV1 {
        return {
            name: value.name,
            value: value.value,
            unit: value.unit
        };
    }

    public static toEntity(value: MeasuredValueV1): MeasuredValue {
        return {
            name: value.name,
            value: value.value,
            unit: value.unit
        };
    }
}
