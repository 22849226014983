import { AbstractEntityV1 } from "./abstract-entity.v1";
import { ImageV1 } from "./image.v1";
import { PropertyV1 } from "./property.v1";

/**
 *
 */
export class PartV1 extends AbstractEntityV1 {
    public image: ImageV1|undefined;
    public properties: Array<PropertyV1> = [];
}
