import { Injectable } from "@angular/core";

import { CloneUtil } from "../../../base/helper/clone-util";
import { CryptoHelper } from "../../../base/helper/crypto-helper";
import { Part } from "../../datamodel/part";
import { Property } from "../../datamodel/property";
import { db } from "../../db";
import { ImageService } from "../image/image-service";

/**
 * PartService is able to store and load parts.
 */
@Injectable({
    providedIn: "root"
})
export class PartService {
    constructor(private imageService: ImageService) {}

    public async listParts(): Promise<Array<Part>> {
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        return db.transaction("r", db.parts, async () => await db.parts.orderBy("id").reverse().toArray());
    }

    public async save(part: Part, newCorrelationId: boolean = false): Promise<number> {
        if (newCorrelationId) {
            part.correlationId = CryptoHelper.getUUID();
        }
        return db.transaction("rw", db.parts, async () => {
            let partId: number;
            if (part.id) {
                await db.parts.update(part.id, part);
                partId = part.id;
            } else {
                partId = await db.parts.add(part);
            }
            console.info(`Part ${part.id} (${part.correlationId}) saved.`);
            return partId;
        });
    }

    public async duplicate(part: Part): Promise<number|undefined> {
        let imageId: number|undefined;
        if (part.image?.binaryId) {
            const image: Blob|undefined = await this.imageService.load(part.image.binaryId);
            if (image) {
                imageId = await this.imageService.save(image);
            }
        }
        const clone: Part = CloneUtil.clone(part);
        clone.id = undefined;
        clone.id = await this.save(clone, true);
        if (clone.image) {
            clone.image.binaryId = imageId;
        }
        const nameProperty: Property|undefined = Property.findByNameKey(Part.partPropertyDescription, clone.properties);
        if (nameProperty) {
            nameProperty.value = `${nameProperty.value} Clone#${clone.id}`;
        }
        await this.save(clone);
        return clone.id;
    }

    public async load(id: number): Promise<Part|undefined> {
        return db.transaction("r", db.parts,
            async () => await db.parts.where({ "id": id }).first());
    }

    public async loadByCorrelationId(id: string): Promise<Part|undefined> {
        return db.transaction("r", db.parts,
            async () => await db.parts.where({ "correlationId": id }).first());
    }

    public async delete(id: number): Promise<void> {
        await db.transaction("rw", db.parts,
            async () => await db.parts.where({ "id": id }).delete());
    }
}
