import { Component, ElementRef, ViewChild } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute } from "@angular/router";
import { FileSharer } from "@byteowls/capacitor-filesharer";
import { TranslateService } from "@ngx-translate/core";
import { DateTime } from "luxon";
import Swiper from "swiper";
import { SwiperContainer } from "swiper/swiper-element";

import { BaseComponent } from "../../../base/components/base/base-component";
import { ComponentCanDeactivate } from "../../../base/guards/navigate-away.guard";
import { AsyncHelper } from "../../../base/helper/async-helper";
import { CryptoHelper } from "../../../base/helper/crypto-helper";
import { BluetoothService } from "../../../base/services/ble/bluetooth.service";
import { NavigationService } from "../../../base/services/navigation/navigation.service";
import { CsvExportSettingsComponent } from "../../components/csv-export-settings/csv-export-settings.component";
import { GraphComponent } from "../../components/graph/graph.component";
import { MeasurementTableComponent } from "../../components/measurement-table/measurement-table.component";
import { Chart } from "../../datamodel/chart";
import { Job } from "../../datamodel/job";
import { JobTemplate } from "../../datamodel/job-template";
import { MeasuredPoint } from "../../datamodel/measured-point";
import { Measurement } from "../../datamodel/measurement";
import { MeasurementPoint } from "../../datamodel/measurement-point";
import { Part } from "../../datamodel/part";
import { Photo } from "../../datamodel/photo";
import { Property } from "../../datamodel/property";
import { CsvSettings } from "../../entities/csv/csv-settings";
import { UiHelper } from "../../helpers/ui-helper";
import { CsvExporterService } from "../../services/csv-exporter/csv-exporter.service";
import { DialogService } from "../../services/dialog/dialog.service";
import { ImageService } from "../../services/image/image-service";
import { JobService } from "../../services/job/job.service";
import { JobModePdf } from "../../services/pdf/documents/jobmode.pdf";
import { SettingsService } from "../../services/settings/settings-service";
import { JobTemplateExporter } from "../job-designer/sharing/job-template-exporter";

/**
 * The main job-mode view, where the actual measurements are being taken.
 */
@Component({
    selector: "app-job-mode-measurement",
    templateUrl: "./job-mode-measurement.component.html",
    styleUrls: ["./job-mode-measurement.component.scss"]
})
export class JobModeMeasurementComponent extends BaseComponent implements ComponentCanDeactivate {
    constructor(
        private readonly route: ActivatedRoute,
        private readonly jobService: JobService,
        private readonly bluetoothService: BluetoothService,
        private readonly snackbar: MatSnackBar,
        private readonly translateService: TranslateService,
        private readonly navigationService: NavigationService,
        private readonly jobModePdf: JobModePdf,
        private readonly imageService: ImageService,
        private readonly dialogService: DialogService,
        private readonly jobTemplateExporter: JobTemplateExporter,
        private readonly csvExporterService: CsvExporterService,
        private readonly settingsService: SettingsService
    ) {
        super();
    }

    protected exportingPdf: boolean = false;
    protected exportingCsv: boolean = false;

    public currentMeasurements: Array<Measurement> = [];
    public currentMeasurementPoint?: MeasurementPoint;
    public currentPart?: Part;
    public currentPhotos: Array<Photo> = [];
    protected readonly jobClass: typeof Job = Job;
    protected job?: Job;
    protected jobDescription?: string;

    protected canSelectPreviousPart: boolean = false;
    protected canSelectNextPart: boolean = false;

    protected chartsCount: number = 0;

    public slideIndex: number = 0;

    private isModified: boolean = false;

    private swiperContainerElementRefField?: ElementRef<SwiperContainer>;
    @ViewChild("measurementTable")
    private measurementTableComponent?: MeasurementTableComponent;

    public get swiperContainerElementRef(): ElementRef<SwiperContainer>|undefined {
        return this.swiperContainerElementRefField;
    }

    @ViewChild("swiperContainer")
    public set swiperContainerElementRef(value: ElementRef<SwiperContainer>) {
        this.swiperContainerElementRefField = value;
        this.swiperInitialized();
    }

    public async willDeactivate(): Promise<void> {
        if (this.isModified) {
            await this.save();
        }
    }

    public async canDeactivate(): Promise<boolean> {
        return !this.isModified;
    }

    protected componentInit(): void {
        this.initialize().then();
    }

    private updateCanSelectParts(): void {
        this.canSelectPreviousPart = this.slideIndex > 0;
        this.canSelectNextPart = this.slideIndex + 1 < (this.swiperContainerElementRef?.nativeElement?.swiper?.slides?.length ?? 0);
    }

    private swiperInitialized(): void {
        const swiperInstance: Swiper|undefined = this.swiperContainerElementRef?.nativeElement.swiper;
        if (!swiperInstance) {
            return;
        }

        swiperInstance.on("slideChange", () => {
            this.refreshPart();
        });

        swiperInstance.params.autoHeight = false;
        if (swiperInstance.params.cardsEffect) {
            swiperInstance.params.cardsEffect.rotate = false;
        }
        swiperInstance.update();

        this.updateCanSelectParts();
    }

    private async initialize(): Promise<void> {
        const jobId: string|null = this.route.snapshot.paramMap.get("id");
        if (jobId == null) {
            return;
        }

        this.job = await this.jobService.load(parseInt(jobId, 10));
        if (this.job?.parts && this.job.parts.length > 0) {
            this.currentPart = this.job.parts[0];
            if (this.currentPart.image?.measurementPoints && this.currentPart.image.measurementPoints.length > 0) {
                this.currentMeasurementPoint = this.currentPart.image?.measurementPoints[0];
            }
            this.refreshPart();
            this.refreshMeasurements();
        }
        this.jobDescription = this.job?.properties.find((property: Property) => property.nameKey == JobTemplate.jobTemplatePropertyName)?.value;

        this.subscribe(this.bluetoothService.onData, this.bluetoothDataReceived);
    }

    protected componentDestroy(): void {
        // empty
    }

    private bluetoothDataReceived(newMeasurement: Measurement): void {
        if (!this.componentIsActive) {
            return;
        }

        if (this.currentPart?.id && this.currentMeasurementPoint?.id) {
            // Assign new ID
            let maxIndex: number = 0;
            for (const measuredPoint of this.job?.measuredPoints ?? []) {
                for (const measurement of measuredPoint.measurements) {
                    maxIndex = Math.max(maxIndex, isNaN(measurement.localId) || measurement.localId <= 0 ? 0 : measurement.localId);
                }
            }
            newMeasurement.localId = maxIndex + 1;

            const existingMeasuredPoint: MeasuredPoint|undefined = this.job?.measuredPoints.find((point: MeasuredPoint) => point.measurementPointId == this.currentMeasurementPoint?.id);

            if (existingMeasuredPoint) {
                existingMeasuredPoint.measurements.unshift(newMeasurement);
            } else {
                this.job?.measuredPoints.unshift(new MeasuredPoint(this.currentPart.id, this.currentMeasurementPoint.id, [newMeasurement]));
            }

            this.save().then();

            this.bluetoothService.deleteMeasurement(newMeasurement);

            this.refreshMeasurements();

            if (this.measurementTableComponent) {
                this.measurementTableComponent.measurements = this.currentMeasurements;
                this.measurementTableComponent.refresh();
            }
        }
    }

    public async onNewPhoto(file: File): Promise<void> {
        if (this.job && this.currentPart?.id) {
            const arrayBuffer: ArrayBuffer = await file.arrayBuffer();
            const binaryId: number = await this.imageService.save(new Blob([arrayBuffer], { type: file.type }));
            const photo: Photo = {
                partId: this.currentPart.id,
                binaryId: binaryId,
                mimeType: file.type
            };
            this.job.photos.push(photo);
            await this.save();
            this.refreshPhotos();
        }
    }

    public async onRemovePhoto(photo: Photo): Promise<void> {
        if (this.job && photo.binaryId) {
            this.job.photos = this.job?.photos.filter((value: Photo) => value.binaryId != photo.binaryId);
            await this.imageService.delete(photo.binaryId);
            await this.save();
        }
        this.refreshPhotos();
    }

    public nextPart(): void {
        this.swiperContainerElementRef?.nativeElement?.swiper.slideNext();
        this.refreshPart();
    }

    public previousPart(): void {
        this.swiperContainerElementRef?.nativeElement?.swiper.slidePrev();
        this.refreshPart();
    }

    public async save(autosave: boolean = true): Promise<void> {
        if (this.job) {
            this.job.dateISO = DateTime.now().toISO()!;
            await this.jobService.save(this.job);
        }

        if (!autosave) {
            this.snackbar.open(this.translateService.instant("JobMode.saved"), undefined, {
                duration: this.snackbarDuration,
                verticalPosition: this.snackbarVerticalPosition
            });
        }
    }

    public markerChanged(measurementPoint: MeasurementPoint): void {
        this.currentMeasurementPoint = measurementPoint;
        this.refreshMeasurements();
    }

    public async exportPdf(): Promise<void> {
        if (this.job) {
            try {
                this.exportingPdf = true;
                // eslint-disable-next-line @typescript-eslint/no-magic-numbers
                await AsyncHelper.sleep(10);

                await this.jobModePdf.generatePDF(this.job, async (base64Data: string) => {
                    try {
                        await FileSharer.share({
                            filename: this.getFilename("pdf"),
                            contentType: "application/pdf",
                            base64Data: base64Data
                        });
                    } catch (error) {
                        console.error(error);
                    } finally {
                        this.exportingPdf = false;
                    }
                });
            } catch (error) {
                this.exportingPdf = false;
            }
        }
    }

    public async delete(): Promise<void> {
        if (this.job?.id) {
            const result: boolean = await this.dialogService.openDeleteDialog("DeleteDialog.title", "DeleteDialog.description", "", "");

            if (result && this.job?.id) {
                await this.jobService?.delete(this.job.id);
                await this.navigationService.navigateBack();
                this.snackbar.open(this.translateService.instant("JobMode.deleted"), undefined, {
                    duration: this.snackbarDuration,
                    verticalPosition: this.snackbarVerticalPosition
                });
            }

        }
    }

    public async updateProperties(properties: Array<Property>): Promise<void> {
        if (this.job) {
            this.job.properties = [];
            for (const property of properties) {
                if (!property.deleted) {
                    this.job.properties.push(property);
                }
            }

            await this.save();
        }
    }

    public refreshPhotos(): void {
        const photos: Array<Photo> = [];
        if (this.job && this.currentPart) {
            for (const photo of this.job.photos) {
                if (photo.partId == this.currentPart.id) {
                    photos.push(photo);
                }
            }
        }
        this.currentPhotos = photos;
    }

    private refreshMeasurements(): void {
        const measurements: Array<Measurement> = [];
        if (!this.currentPart || !this.currentMeasurementPoint) {
            return;
        }

        const measurementsForMeasuredPoint: Array<Measurement>|undefined = this.job?.measuredPoints
            .filter((measuredPoint: MeasuredPoint) =>
                measuredPoint.measurementPointId == this.currentMeasurementPoint?.id)
            .map((measuredPoint: MeasuredPoint) => measuredPoint.measurements)
            .flat();

        if (measurementsForMeasuredPoint) {
            measurements.push(...measurementsForMeasuredPoint);
        }


        let chartsCount: number = 0;
        for (const measuredPoint of this.job?.measuredPoints ?? []) {
            if (measuredPoint.measurementPointId == this.currentMeasurementPoint?.id) {
                measuredPoint.measurements.forEach((measurement: Measurement) => {
                    measurement.charts.forEach(() => {
                        chartsCount++;
                    });
                });
            }
        }
        this.chartsCount = chartsCount;

        this.currentMeasurements = measurements;
    }

    private refreshPart(): void {
        this.slideIndex = this.swiperContainerElementRef?.nativeElement.swiper.activeIndex ?? 0;
        if (this.job && this.swiperContainerElementRef) {
            this.currentPart = this.job.parts[this.slideIndex];
            this.refreshMeasurementPoint();
            this.refreshMeasurements();
        }
        this.refreshPhotos();
        this.updateCanSelectParts();
    }

    private refreshMeasurementPoint(): void {
        let currentMeasurementPointIsOnCurrentPart: boolean = false;
        if (this.currentPart?.image) {
            for (const mp of this.currentPart?.image?.measurementPoints) {
                if (this.currentMeasurementPoint?.id == mp.id) {
                    currentMeasurementPointIsOnCurrentPart = true;
                    break;
                }
            }
        }
        if (!currentMeasurementPointIsOnCurrentPart
            && this.currentPart?.image?.measurementPoints
            && this.currentPart?.image?.measurementPoints.length > 0) {
            this.currentMeasurementPoint = this.currentPart.image.measurementPoints[0];
        }
    }

    public getPartCaption(part: Part): string {
        return Property.findByNameKey(Part.partPropertyDescription, part.properties)?.value ?? "XX";
    }


    public async exportJob(): Promise<void> {
        if (this.job) {
            await this.jobTemplateExporter.exportJob(this.job);
        }
    }

    public async chartAdded(imageDataUrl?: string): Promise<void> {
        if (!imageDataUrl || !this.currentPart || !this.job) {
            return;
        }

        const data: string = imageDataUrl.replace(/^[^,]+,/, "");
        const imageData: string = atob(data);

        const len: number = imageData.length;
        const buffer: ArrayBuffer = new ArrayBuffer(len);
        const view: Uint8Array = new Uint8Array(buffer);

        for (let i: number = 0; i < len; i++) {
            view[i] = imageData.charCodeAt(i);
        }

        const binaryId: number = await this.imageService.save(new Blob([buffer], { type: "image/png" }));
        const photo: Photo = {
            partId: this.currentPart.id,
            binaryId: binaryId,
            mimeType: "image/png"
        };

        this.job.photos.push(photo);
        await this.save();
        this.refreshPhotos();
    }

    public async addCharts(): Promise<void> {
        const allCharts: Array<Chart> = [];
        for (const measuredPoint of this.job?.measuredPoints ?? []) {
            if (measuredPoint.measurementPointId == this.currentMeasurementPoint?.id) {
                measuredPoint.measurements.forEach((measurement: Measurement) => {
                    measurement.charts.forEach((chart: Chart) => {
                        chart.title = UiHelper.measurementToChartTitle(measurement);
                        allCharts.unshift(chart);
                    });
                });
            }
        }

        const imageUrl: string|undefined = await this.dialogService.openDialog<string|undefined>(GraphComponent, {
            fullscreen: true
        }, {
            charts: allCharts,
            allowSave: true
        });

        if (imageUrl) {
            await this.chartAdded(imageUrl);
        }
    }

    protected async deleteMeasurement(measurement: Measurement): Promise<void> {
        this.bluetoothService.deleteMeasurement(measurement);

        if (this.job) {
            for (const mp of this.job.measuredPoints) {
                mp.measurements = mp.measurements.filter((m: Measurement) => m.id != measurement.id);
            }
            await this.save();
            this.refreshPart();
        }
    }

    public async exportCsv(): Promise<void> {
        if (!this.job) {
            return;
        }

        let settings: CsvSettings|undefined = (await this.settingsService.loadSettings()).csvSettings ?? new CsvSettings();
        settings.sourceJob = this.job;
        settings.filename = this.getFilename("csv");

        const allMeasurements: Array<Measurement> = [];
        for (const measuredPoint of this.job?.measuredPoints ?? []) {
            for (const measurement of measuredPoint.measurements) {
                allMeasurements.push(measurement);
            }
        }
        settings.columns = [
            ...(UiHelper.measurementsToColumnNames(allMeasurements))
        ];

        settings = await this.dialogService.openDialog<CsvSettings>(CsvExportSettingsComponent, { fullscreen: false }, { csvSettings: settings });
        if (settings == null) {
            return;
        }
        try {
            this.exportingCsv = true;
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            await AsyncHelper.sleep(10);
            await this.csvExporterService.export(settings);
        } finally {
            this.exportingCsv = false;
        }
    }

    private getFilename(extension: string): string {
        if (!this.job) {
            return `${DateTime.local().toFormat("yyyy-LL-dd")}-${CryptoHelper.getUUID()}.${extension}`;
        }
        return `${DateTime.local().toFormat("yyyy-LL-dd")}-${UiHelper.sanitizeFilename(Job.getJobDisplayName(this.job), true).replace(/[()]/g, "").toLowerCase()}.${extension}`;
    }
}
