import { Property } from "../../../../datamodel/property";

/**
 *
 */
export class PropertyV1 {
    public name?: string;
    public value?: string;
    public nameEditable?: boolean;
    public deletable?: boolean;

    public static fromEntity(entity: Property): PropertyV1 {
        const propertyV1: PropertyV1 = new PropertyV1();
        propertyV1.name = entity.nameKey;
        propertyV1.value = entity.value;
        propertyV1.nameEditable = entity.nameEditable;
        propertyV1.deletable = entity.deletable;
        return propertyV1;
    }

    public static toEntity(v1: PropertyV1): Property {
        const property: Property = new Property();
        property.nameKey = v1.name;
        property.value = v1.value;
        property.nameEditable = v1.nameEditable ?? true;
        property.deletable = v1.deletable ?? true;
        property.deleted = false;
        property.isNew = false;
        return property;
    }
}
