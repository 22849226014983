import { AbstractEntity } from "../../datamodel/abstract-entity";
import { CsvSettings } from "../../entities/csv/csv-settings";

/**
 * Entity to store personalization data.
 */
export class AppSettings extends AbstractEntity {
    public language?: string = "en";

    public companyName?: string;
    public companyAddress?: string;
    public website?: string;
    public phone?: string;
    public email?: string;
    public logoAsDataUrl?: string;

    public lengthUnit?: string = "µm";

    public csvSettings?: CsvSettings;
}
