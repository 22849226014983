import { Component, EventEmitter, OnDestroy, OnInit, Output } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";

import { AppIcons } from "../../../app-icons";
import { BluetoothService } from "../../../base/services/ble/bluetooth.service";
import { BluetoothConnection } from "../../../base/services/ble/bluetooth-connection";
import { BluetoothStatus } from "../../../base/services/ble/bluetooth-status";
import { BluetoothDevice } from "../../../base/services/ble/devices/bluetooth.device";
import { NavigationService } from "../../../base/services/navigation/navigation.service";
import { UiHelper } from "../../helpers/ui-helper";
import { DialogService } from "../../services/dialog/dialog.service";
import { ScanDevicesComponent } from "../scan-devices/scan-devices.component";

/**
 * DeviceConnectorComponent shows a list of supported bluetooth devices and offers the user the option to select the desired one.
 */
@Component({
    selector: "app-device-connector",
    templateUrl: "./device-connector.component.html",
    styleUrls: ["./device-connector.component.scss"]
})
export class DeviceConnectorComponent implements OnInit, OnDestroy {
    constructor(
        private readonly bluetoothService: BluetoothService,
        private readonly navigationService: NavigationService,
        private readonly dialogService: DialogService,
        private readonly translateService: TranslateService
    ) {
    }

    protected readonly icons: typeof AppIcons = AppIcons;
    protected readonly uiHelper: typeof UiHelper = UiHelper;

    protected connections: Array<BluetoothConnection> = [];

    public bluetoothStatus: BluetoothStatus = BluetoothStatus.unknown;

    @Output("deviceSelectedEvent")
    public deviceSelectedEvent: EventEmitter<BluetoothDevice> = new EventEmitter<BluetoothDevice>();

    private connectionStatusSubscription?: Subscription;

    public ngOnInit(): void {
        const self: DeviceConnectorComponent = this;
        this.connectionStatusSubscription = this.bluetoothService.allDevicesStatusChanged.subscribe((next: BluetoothStatus) => {
            self.bluetoothStatus = next;
            self.updateConnections();
        });

        this.connections = this.bluetoothService.connections;
        this.bluetoothStatus = this.bluetoothService.allDevicesStatus;
    }

    public ngOnDestroy(): void {
        if (this.connectionStatusSubscription) {
            this.connectionStatusSubscription.unsubscribe();
        }
    }

    private updateConnections(): void {
        this.connections = this.bluetoothService.connections;
    }

    public async disconnect(connection?: BluetoothConnection): Promise<void> {
        if (connection) {
            if (await this.dialogService.confirm(this.translateService.instant("DeviceConnector.disconnect"), this.translateService.instant("DeviceConnector.askDisconnect"))) {
                await this.bluetoothService.disconnect(connection.device);
            }
        } else {
            await this.bluetoothService.disconnectAll();
        }
    }

    public openBleStatusPage(): Promise<boolean> {
        return this.navigationService.navigateForward("ble-status");
    }

    public async scanDevices(): Promise<void> {
        await this.dialogService.openDialog(ScanDevicesComponent);
    }
}
