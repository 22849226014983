import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Device, DeviceInfo } from "@capacitor/device";
import { lastValueFrom, throwError } from "rxjs";
import { catchError } from "rxjs/operators";

import { environment } from "../../../../environments/environment";
import { CryptoHelper } from "../../../base/helper/crypto-helper";
import { AppService } from "../app/app.service";

/**
 * Service to send error reports to the reporting server.
 */
@Injectable({
    providedIn: "root"
})
export class ReportService {
    private readonly endpointUrl: string = environment.reportUrl;

    constructor(
        private readonly appService: AppService,
        private readonly httpClient: HttpClient
    ) {
    }

    public async sendReport(fileContent: string, manualTrigger: boolean = false): Promise<boolean> {
        try {
            if (!manualTrigger && this.appService.stage && this.appService.stage[0] == "#") {
                console.info("Skipping report upload.");
                return false;
            }

            const time: string = `${new Date().toISOString().split(".")[0]}Z`;
            const category: string = this.appService.stage[0] == "#" ? "dev" : this.appService.stage;
            const version: string = this.appService.currentVersion[0] == "#" ? "0.0.0+dev" : this.appService.currentVersion;
            const clientId: string = this.appService.clientId;
            const productId: string = environment.productId;
            const securityCode: string = await this.generateSecurityCode(productId, category, version, clientId, time);

            let text: string = `${fileContent}\n`;

            try {
                const deviceInfo: DeviceInfo = await Device.getInfo();
                text += "# System\n";
                text += `Device name:  ${deviceInfo.name}\n`;
                text += `Model:        ${deviceInfo.model}\n`;
                text += `Platform:     ${deviceInfo.platform}\n`;
                text += `OS:           ${deviceInfo.operatingSystem}\n`;
                text += `OS Version:   ${deviceInfo.osVersion}\n`;
                text += `iOS:          ${deviceInfo.iOSVersion}\n`;
                text += `Android:      ${deviceInfo.androidSDKVersion}\n`;
                text += `Manufacturer: ${deviceInfo.manufacturer}\n`;
                text += `Web View:     ${deviceInfo.webViewVersion}\n`;
                text += `Is Virtual:   ${deviceInfo.isVirtual ? "yes" : "no"}\n`;
            } catch (error) {
                text += `Unable to get system info.\n${error}`;
                console.warn("Unable to get system info.", error);
            }

            const formData: FormData = new FormData();
            formData.append("file", new File([text], "report.log"));

            const url: string = `${this.endpointUrl}/?product=${encodeURIComponent(productId)}&category=${encodeURIComponent(category)}&version=${encodeURIComponent(version)}&client=${encodeURIComponent(clientId)}&time=${encodeURIComponent(time)}&code=${encodeURIComponent(securityCode)}`;

            const headers: HttpHeaders = new HttpHeaders();
            let successful: boolean = true;
            await lastValueFrom(this.httpClient.post<void>(url, formData, { headers }).pipe(
                catchError((error: any) => {
                    successful = false;
                    console.warn("Error reporting logs.", error);
                    return throwError(() => error);
                })
            ));
            console.info("Report sent.");
            return successful;
        } catch (error) {
            console.warn("Unable to report to server.", error);
            return false;
        }
    }

    /**
     * Generates the security code used for authentication.
     * @param product - The product identifier.
     * @param category - The category.
     * @param version - The version.
     * @param clientId - The unique client ID.
     * @param time - The timestamp in ISO format.
     * @returns String - The SHA-256 security hash.
     */
    private async generateSecurityCode(product: string, category: string, version: string, clientId: string, time: string): Promise<string> {
        return CryptoHelper.sha256(`ril-${product}-${category}-${version}-${clientId}-${time}-lir`);
    }
}
