/**
 * Class to define sort orders for various entities.
 */
export class SortOrders {
    public static readonly metricsSortOrder = [
        "GLOSS20",
        "GLOSS60",
        "GLOSS85",
        "RSPEC",
        "HAZE",
        "HAZE",
        "LOGHAZE",
        "LOGHAZE",
        "LOGHAZE C",
        "HAZE C",
        "HAZE C",
        "ASTM D4039",
        "DOI",
        "RIQ",

        "DATE",
        "TIME",
        "PASS/FAIL",
        "CALIBRATED",
        "SERIAL NO.",
        "CERTIFIED",
        "CDIODE"
    ];
}
