<app-menubar [header]="caption" [showBackButton]="true"></app-menubar>

<ion-content [fullscreen]="true">
    <ion-header collapse="condense">
        <ion-toolbar>
            <ion-title size="large">{{caption| translate}}</ion-title>
        </ion-toolbar>
    </ion-header>

    <div class="content">
        <app-part-editor #partEditorComponent [part]="part" mode="edit"></app-part-editor>

        <div class="buttons">
            <div class="actionButtons">
                <button
                    (click)="duplicate()"
                    *ngIf="part.id"
                    class="smallButton"
                    color="primary"
                    mat-flat-button>
                    {{'Generic.duplicate' | translate}}
                </button>

                <button
                    (click)="delete()"
                    *ngIf="part.id"
                    class="smallButton"
                    color="warn"
                    mat-flat-button>
                    {{'Generic.delete' | translate}}
                </button>
            </div>
        </div>
    </div>
</ion-content>
