import { MeasuredPoint } from "../../../../datamodel/measured-point";
import { MeasurementV1 } from "./measurement.v1";

/**
 *
 */
export class MeasuredPointV1 {
    public partId: number = 0;
    public measurementPointId: string = "";
    public measurements: Array<MeasurementV1> = [];

    public static toEntity(v1: MeasuredPointV1): MeasuredPoint {
        const entity: MeasuredPoint = new MeasuredPoint(v1.partId, v1.measurementPointId, []);

        for (const measurementV1 of v1.measurements) {
            entity.measurements.push(MeasurementV1.toEntity(measurementV1));
        }

        return entity;
    }
}
