import { Job } from "../../datamodel/job";
import { Measurement } from "../../datamodel/measurement";

/**
 *
 */
export class CsvSettings {
    public filename: string = "measurements.csv";

    public delimiter: string = ";";
    public encoding: string|"UTF-8"|"UTF-8-BOM"|"Windows-1252" = "UTF-8";

    public timeFormat: string|"iso-utc"|"iso-local" = "iso-utc";
    public decimalSeparator: string|"."|"," = ".";
    public units: boolean = true;
    public gonioCurve: boolean = true;

    public columns: Array<string> = [];

    public sourceMeasurements: Array<Measurement> = [];
    public sourceJob?: Job;

    public selectedMeasurements: Array<Measurement> = [];
}
