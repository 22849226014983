import { Injectable } from "@angular/core";

import { CryptoHelper } from "../../../base/helper/crypto-helper";
import { db } from "../../db";
import { UiHelper } from "../../helpers/ui-helper";
import { AppSettings } from "./app-settings";

/**
 *
 */
@Injectable({
    providedIn: "root"
})
export class SettingsService {

    public licenseCheckEnabled: boolean = true;

    public async saveSettings(personalization: AppSettings): Promise<number> {
        personalization.lengthUnit ??= "µm";
        UiHelper.lengthUnit = personalization.lengthUnit;

        return db.transaction("rw", db.personalization, async () => {
            let personalizationId: number;
            if (personalization.id) {
                await db.personalization.update(personalization.id, personalization);
                personalizationId = personalization.id;
            } else {
                personalizationId = await db.personalization.add(personalization);
            }
            return personalizationId;
        });
    }

    public async loadSettings(): Promise<AppSettings> {
        const data: Array<AppSettings> = await db.personalization.toArray();
        let personalization: AppSettings = new AppSettings();
        if (data.length > 0) {
            personalization = data[0];
        } else {
            personalization.version = 1;
            personalization.correlationId = CryptoHelper.getUUID();
        }
        return personalization;
    }
}
