import { Component, ElementRef, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ModalController, Platform } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";

import { BaseComponent } from "../../../base/components/base/base-component";
import { NavigationService } from "../../../base/services/navigation/navigation.service";
import { ErrorDialogComponent } from "../../components/error-dialog/error-dialog.component";
import { ErrorMessage } from "../../components/error-dialog/error-message";
import { SelectJobTemplateComponent } from "../../components/select-job-template/select-job-template.component";
import { Job } from "../../datamodel/job";
import { JobTemplate } from "../../datamodel/job-template";
import { JobService } from "../../services/job/job.service";
import { JobTemplateImporter } from "../job-designer/sharing/job-template-importer";

/**
 * Start page for the Job Mode. Displays a list with previous jobs.
 */
@Component({
    selector: "app-job-list",
    templateUrl: "./job-list.component.html",
    styleUrls: ["./job-list.component.scss"]
})
export class JobListComponent extends BaseComponent {
    constructor(
        private readonly jobService: JobService,
        private readonly dialog: MatDialog,
        private readonly navigationService: NavigationService,
        private readonly modalController: ModalController,
        private jobTemplateImporter: JobTemplateImporter,
        private translateService: TranslateService,
        private snackbar: MatSnackBar,
        platform: Platform
    ) {
        super();
        this.isiOS = platform.is("ios");
    }

    protected readonly jobClass: typeof Job = Job;
    protected jobs: Array<Job> = [];
    protected filteredJobs: Array<Job> = [];
    protected searchQuery: string = "";
    protected isiOS: boolean;

    @ViewChild("fileSelector")
    private fileSelectorRef?: ElementRef<HTMLInputElement>;

    protected componentInit(): void {
        // Do nothing for now
    }

    protected componentDestroy(): void {
        // Do nothing for now
    }

    protected override componentEnter(): void {
        this.initialize().then();
    }

    private async initialize(): Promise<void> {
        await this.updateJobs();
    }

    private async updateJobs(): Promise<void> {
        this.jobs = await this.jobService.listJobs();
        this.applySearchFilter();
    }

    public emptyQuery(): void {
        this.searchQuery = "";
        this.applySearchFilter();
    }

    public applySearchFilter(): void {
        const query: string = this.searchQuery.trim().toLowerCase();

        if (!query) {
            this.filteredJobs = this.jobs;
            return;
        }

        const filtered: Array<Job> = [];
        const queryAsNumber: number = Number.parseInt(query, 10);

        // Prefer ID matches
        const listToSearch: Array<Job> = [];
        for (const job of this.jobs) {
            if ((!Number.isNaN(queryAsNumber) && job.id == queryAsNumber)) {
                filtered.push(job);
            } else {
                listToSearch.push(job);
            }
        }

        // Search all other properties
        for (const job of listToSearch) {
            for (const property of job.properties) {
                if (property.value?.toLowerCase().includes(query)) {
                    filtered.push(job);
                    break;
                }
            }
        }

        this.filteredJobs = filtered;
    }

    public navigateTo(path: string): void {
        this.navigationService.navigateForward(path).then();
    }

    public async startNewJob(): Promise<void> {
        const modal: HTMLIonModalElement = await this.modalController.create({
            component: SelectJobTemplateComponent,
            presentingElement: document.querySelector(".ion-page") as HTMLElement
        });

        await modal.present();

        const { data }: { data?: JobTemplate } = await modal.onWillDismiss<JobTemplate>() as {
            data?: JobTemplate;
        };

        if (data) {
            const jobId: number = await this.jobService.createJob(data);
            await this.navigationService.navigateForward(`jobs/${jobId}`);
        }
    }

    public async navigateToJob(job: Job|JobTemplate): Promise<void> {
        await this.navigationService.navigateForward(`jobs/${job.id}`);
    }

    public importJob(): void {
        if (!this.fileSelectorRef) {
            return;
        }

        this.fileSelectorRef.nativeElement.onchange = async (event: Event): Promise<void> => {
            const files: FileList|null = (event.target as HTMLInputElement)?.files;
            if (files && files.length > 0) {
                try {
                    this.snackbar.open(this.translateService.instant("JobTemplateList.importing"), undefined, {
                        duration: this.snackbarDuration,
                        verticalPosition: this.snackbarVerticalPosition
                    });
                    const job: Job|undefined = await this.jobTemplateImporter.import(files[0]) as Job|undefined;
                    if (!job) {
                        // noinspection ExceptionCaughtLocallyJS
                        throw new Error("Job importer returned an empty result.");
                    }
                    await this.jobService.save(job);
                    await this.updateJobs();
                    this.snackbar.open(this.translateService.instant("JobTemplateList.jobTemplateImported"), undefined, {
                        duration: this.snackbarDuration,
                        verticalPosition: this.snackbarVerticalPosition
                    });
                } catch (error: Error|any) {
                    this.dialog.open(ErrorDialogComponent, {
                        data: {
                            title: "ErrorDialog.importFailed",
                            advice: [
                                "ErrorDialog.adviceContactSupport"
                            ],
                            details: [`${error.message}` ?? ""],
                            severity: "error",
                            buttons: []
                        } as ErrorMessage
                    });
                } finally {
                    if (this.fileSelectorRef) {
                        this.fileSelectorRef.nativeElement.value = "";
                    }
                }
            }
        };
        this.fileSelectorRef.nativeElement.click();
    }
}
