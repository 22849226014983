<ion-header [translucent]="true">
    <ion-toolbar>
        <ion-title>{{'JobTemplateSelector.title' | translate}}</ion-title>

        <ion-buttons slot="end">
            <ion-button (click)="cancel()">{{'Generic.cancel' | translate}}</ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true">
    <div class="content">
        <mat-action-list>
            <button (click)="select(jobTemplate)" *ngFor="let jobTemplate of jobTemplates" mat-list-item>
                <fa-icon [icon]="appIcons.jobModeIcon" matListItemIcon></fa-icon>
                <span matListItemTitle>{{jobTemplate.id}} - {{jobTemplateClass.getJobTemplateDisplayName(jobTemplate)}}</span>
                <fa-icon [icon]="appIcons.clickableListItemIcon" matListItemMeta></fa-icon>
            </button>
        </mat-action-list>
        <p *ngIf="jobTemplates.length <= 0">{{'JobTemplateSelector.noTemplates' | translate}}</p>
    </div>
</ion-content>
