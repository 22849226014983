<ion-split-pane when="xs" contentId="main">
    <ion-menu contentId="main">
        <ion-content class="ion-padding" [fullscreen]="true">
            <div class="sideBar">
                <h1>{{'Home.title' | translate}}</h1>

                <ng-container *ngFor="let menuItem of items">
                    <button
                        (click)="navigate(menuItem.path)"
                        *ngIf="menuItem.visibility != 'tabs' && menuItem.visibility != 'none'"
                        [class.active]="menuItem.path == activePath"
                        mat-flat-button>
                        <div class="buttonContent">
                            <fa-icon [icon]="menuItem.icon"></fa-icon>
                            <span>{{menuItem.nameKey | translate}}</span>
                        </div>
                    </button>
                </ng-container>

                <div class="spacer"></div>

                <button (click)="updateApp()"
                        *ngIf="restartRequired"
                        color="warn"
                        mat-button>
                    <div class="buttonContent">
                        <fa-icon [icon]="appIcons.appUpdate"></fa-icon>
                        <span>{{'Generic.updateAvailable' | translate}}</span>
                    </div>
                </button>

                <button (click)="installApp()"
                        *ngIf="!appState.pwaInstalled"
                        color="primary"
                        mat-button>
                    <div class="buttonContent">
                        <fa-icon [icon]="appIcons.installApp"></fa-icon>
                        <span>{{'Settings.installApp' | translate}}</span>
                    </div>
                </button>

                <button
                    (click)="navigate('settings')"
                    [class.active]="activePath == 'settings'"
                    mat-flat-button>
                    <div class="buttonContent">
                        <fa-icon [icon]="appIcons.settingsIcon"></fa-icon>
                        <span>{{'Generic.settings' | translate}}</span>
                    </div>
                </button>
            </div>
        </ion-content>
    </ion-menu>

    <ion-router-outlet id="main"></ion-router-outlet>
</ion-split-pane>
