export enum SpecialColumns {
    actions = "MeasurementTable.actions",
    localId = "Measurement.localId",
    name = "Measurement.name",
    comments = "Measurement.comment",
    charts = "Measurement.curve",
    device = "Measurement.device",
    deviceId = "Measurement.deviceId",
    deviceSerialNumber = "Measurement.serialNumber",
    timestamp = "Measurement.timestamp",
    measurementPoint = "Measurement.measurementPoint",
    statisticType = "Statistics.type"
}
