import { Injectable } from "@angular/core";

import { CryptoHelper } from "../../../base/helper/crypto-helper";
import { JobTemplate } from "../../datamodel/job-template";
import { Part } from "../../datamodel/part";
import { JobTemplateService } from "../job-template/job-template.service";
import { PartService } from "../part/part-service";

/**
 * Service to migrate local data.
 */
@Injectable({
    providedIn: "root"
})
export class MigrationService {
    constructor(
        private readonly partService: PartService,
        private readonly templateService: JobTemplateService
    ) {
    }

    public async runMigration(): Promise<void> {
        await Promise.all([
            this.migrateParts(),
            this.migrateTemplates()
        ]);
    }

    public async migrateParts(): Promise<void> {
        const allParts: Array<Part> = await this.partService.listParts();
        for (const part of allParts) {
            if (!part.correlationId) {
                console.info(`Part ${part.id} does not have a correlation ID, migrating...`);
                await this.partService.save(part, true);
            }
        }
    }

    public async migrateTemplates(): Promise<void> {
        const allTemplates: Array<JobTemplate> = await this.templateService.listJobTemplates();
        for (const template of allTemplates) {
            let updated: boolean = false;
            if (!template.correlationId) {
                console.info(`Template ${template.id} does not have a correlation ID, migrating...`);
                template.correlationId = CryptoHelper.getUUID();
                updated = true;
            }

            for (const part of template.parts) {
                if (!part.correlationId) {
                    console.info(`Template-Part ${part.id} does not have a correlation ID, migrating...`);
                    part.correlationId = CryptoHelper.getUUID();
                    updated = true;
                }
            }
            if (updated) {
                await this.templateService.save(template);
            }
        }
    }
}
